import { faImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkIfEveryFileAnImage,
  checkIfEveryFileUnder10MB,
} from '../../../helpers/fileHelpers';

type DropzoneProps = {
  children: React.ReactNode;
  showCTAonDrag?: boolean;
  onDrop: (files: File[]) => void;
  padding?: [number, number];
  resetErrors?: () => void;
  disabled?: boolean;
};

const ImageDropzone = ({
  children,
  showCTAonDrag = false,
  padding = [6, 6],
  disabled = false,

  onDrop,
}: DropzoneProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const [isEveryFileAnImage, setIsEveryFileAnImage] = useState(true);
  const [isEveryFileUnder10MB, setIsEveryFileUnder10MB] = useState(true);

  const { t } = useTranslation(['panel']);

  const resetErrors = () => {
    setIsEveryFileAnImage(true);
    setIsEveryFileUnder10MB(true);
  };

  //reset errors after 8 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      resetErrors();
    }, 8000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isEveryFileAnImage, isEveryFileUnder10MB]);

  //DragOver window event
  //Those events are used to detect if a file is being dragged over the whole window
  useEffect(() => {
    //DragOver event
    const handleDragOverWindow = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };
    window.addEventListener('dragover', handleDragOverWindow);

    //DragLeave event
    const handleDragLeaveWindow = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      resetErrors();
    };
    window.addEventListener('dragleave', handleDragLeaveWindow);

    //DragDrop event
    const handleDropWindow = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
    };
    window.addEventListener('drop', handleDropWindow);

    return () => {
      window.removeEventListener('dragover', handleDragOverWindow);
      window.removeEventListener('dragleave', handleDragLeaveWindow);
      window.removeEventListener('drop', handleDropWindow);
    };
  }, []);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;

    resetErrors();

    e.preventDefault();
    setIsDragging(false);

    //Check if every file is an image and under 10MB
    const files = Array.from(e.dataTransfer.files);

    //Check for incompatible files
    if (!checkIfEveryFileAnImage(files)) {
      setIsEveryFileAnImage(false);
      console.log('Some files are not images');
      return;
    }
    if (!checkIfEveryFileUnder10MB(files)) {
      setIsEveryFileUnder10MB(false);
      console.log('Some files are  over 10MB');
      return;
    }

    console.log('All files are images and under 10MB');

    onDrop(files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.items);
    const isEveryFileImage = checkIfEveryFileAnImage(files);

    if (!isEveryFileImage) {
      setIsEveryFileAnImage(false);
      return;
    }
    setIsEveryFileAnImage(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    resetErrors();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      style={{ padding: `${padding[0] * 4}px ${padding[1] * 4}px` }}
      className={`flex flex-col items-center ${isDragging && !disabled && 'border-2'} w-full rounded-2xl border-dashed border-white p-6`}>
      {(!children || (showCTAonDrag && isDragging && !disabled)) && (
        <div
          className={`${showCTAonDrag && `absolute left-0 top-0 z-100 border-dashed border-white bg-[#CBD5E1] ${isDragging && !disabled && 'border-2'}`} flex h-full w-full flex-col items-center justify-center gap-2 rounded-2xl`}>
          <FontAwesomeIcon icon={faImage} className='h-12'></FontAwesomeIcon>
          <div className='px-6 text-center'>
            {t('panel:Inspire.NewAlbum.DropCTA')}
          </div>
          <div className='text-center text-sm'>
            {isEveryFileAnImage
              ? t('panel:Inspire.NewAlbum.acceptedFormats')
              : t('panel:Inspire.NewAlbum.invalidFiles')}
          </div>
          {!isEveryFileUnder10MB && (
            <div className='text-center text-sm'>
              {t('panel:Inspire.NewAlbum.bigFiles')}
            </div>
          )}
        </div>
      )}

      {children}

      {showCTAonDrag && !isEveryFileUnder10MB && (
        <div className='text-center text-sm'>
          {t('panel:Inspire.NewAlbum.bigFiles')}
        </div>
      )}
    </div>
  );
};

export default ImageDropzone;
