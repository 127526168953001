import {
  faBookOpen,
  faTimes,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState } from 'react';
import useCreateAlbum from '../../../../hooks/albums/useCreateAlbum';

import SimpleButton from '../../../lib/SimpleButton';
import { usePanelContext } from '../context/usePanelContext';

import { Loader2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import ImageDropzone from '../../../lib/Inputs/ImageDropzone';
import { addImagesDialog } from '../../../../helpers/fileHelpers';

const NewAlbum = () => {
  const { t } = useTranslation(['panel']);
  const { createAlbumFromImages, isLoading } = useCreateAlbum();
  // Add setShowAlbum
  const { setShowCreateNewAlbum } = usePanelContext();
  const [filesOver10MB, setFilesOver10MB] = useState<boolean>(false);

  //Event to handle if a file is dropped in the new album zone
  const handleDrop = async (files: File[]) => {
    //Create the album

    createAlbumFromImages(t('Inspire.NewAlbum.defaultTitle'), files);
  };

  const handleAddFiles = () => {
    addImagesDialog(async (files) => {
      if (files.length === 0) {
        setFilesOver10MB(true);
        return;
      }
      setFilesOver10MB(false);

      createAlbumFromImages(t('Inspire.NewAlbum.defaultTitle'), files);
    });
  };

  return (
    <div className='absolute z-[600] w-full rounded-xl bg-[#CBD5E1] p-3 text-text-negative'>
      {/* HEADER */}
      <div className='flex items-center justify-between p-3 py-4'>
        <div className='flex items-center gap-3'>
          <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
          <div className=''>{t('panel:Inspire.NewAlbum.Title')} </div>
        </div>
        <div
          className='flex cursor-pointer items-center'
          onClick={() => {
            setShowCreateNewAlbum(false);
          }}>
          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
        </div>
      </div>
      {/* BODY */}
      <div className={`flex w-full flex-col gap-6 rounded-xl p-4`}>
        {/* CTA */}
        <ImageDropzone onDrop={handleDrop}>
          {isLoading && (
            <div className='flex flex-col items-center gap-4'>
              <Loader2 className='animate-spin text-white' size={30} />
              <div className=''>
                {t('panel:Inspire.NewAlbum.UploadingImages')}
              </div>
            </div>
          )}
        </ImageDropzone>
        {/* IMPORT BUTTON */}
        <div
          className='flex w-full flex-col items-center justify-center gap-3'
          onClick={handleAddFiles}>
          {filesOver10MB && <div>{t('panel:Inspire.NewAlbum.bigFiles')}</div>}
          <div className='flex w-full flex-col gap-3'>
            <SimpleButton
              variant='primary'
              className={'w-full bg-green-to-violet-gradient text-white'}
              icon={faUpload}>
              {t('panel:Inspire.NewAlbum.AddFromFileExplorer')}
            </SimpleButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAlbum;
