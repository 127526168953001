import { useKeycloak } from '@react-keycloak/web';
import { BeinkMode, UserRole } from '../../../../types';
import { usePanelContext } from '../context/usePanelContext';
import { Imagine } from './Imagine';
import { Inpaint } from './Inpaint';

import GenerateButton from '../GenerateButton';
import GenerationsLeft from '../GenerationsLeft';
import LastGeneration from '../Results/LastGeneration';
import PanelNavigation from '../PanelNavigation';

import { AnimatePresence, motion } from 'framer-motion';

export const Generate = () => {
  const { currentMode, isPanelOpen } = usePanelContext();

  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const inpaintAllowed = userRoles.includes(UserRole.Inpaint);

  return (
    <motion.div
      layout='position'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='relative flex w-full basis-full flex-col items-center gap-3'>
      <motion.div
        layout='position'
        className='flex h-full w-full flex-col justify-between'>
        <motion.div layout='position' className='flex w-full flex-col gap-6'>
          <PanelNavigation />
          <AnimatePresence mode='popLayout'>
            {isPanelOpen && (
              <motion.div layout className='w-full'>
                {currentMode === BeinkMode.Imagine && <Imagine />}
                {inpaintAllowed && currentMode === BeinkMode.Inpaint && (
                  <Inpaint />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div layout className='flex w-full flex-col items-center gap-4'>
          {isPanelOpen && <GenerationsLeft />}

          <LastGeneration />
        </motion.div>
      </motion.div>
      <GenerateButton />
    </motion.div>
  );
};
