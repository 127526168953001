import {
  faClockRotateLeft,
  faTrashCan,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTimeAgo from '../../../hooks/useTimeAgo';

import RenamableAlbum from '../../Creator/Panel/Album/RenamableAlbum';
import useGetAlbum from '../../../hooks/albums/useGetAlbum';
import { AlbumShort } from '../../../types/api';
import useArchiveAlbum from '../../../hooks/albums/useArchiveAlbum';
import useRestoreAlbum from '../../../hooks/albums/useRestoreAlbum';
import { motion } from 'framer-motion';
type AlbumListProps = {
  albums: AlbumShort[];
};

const AlbumList = ({ albums }: AlbumListProps) => {
  return (
    <motion.div
      layoutScroll
      className='grid w-full grid-cols-[repeat(5,_fit-content)] gap-2 overflow-y-auto text-sm'>
      <div className='col-span-4 grid grid-cols-subgrid p-4'>
        <div className=''>Nom</div>
        <div className=''>Date</div>
        <div className=''>Description</div>
        <div className=''>Images</div>
      </div>

      {albums.map((album) => {
        return <AlbumRow key={album.id} albumId={album.id} />;
      })}
    </motion.div>
  );
};

const AlbumRow = ({ albumId }: { albumId: string }) => {
  const { album } = useGetAlbum(albumId);
  const { archiveAlbum } = useArchiveAlbum();
  const { restoreAlbum } = useRestoreAlbum();
  const time = useTimeAgo(new Date(album ? album.createdAt : new Date()));
  if (!album) return null;
  const { description, images } = album;

  return (
    <motion.div
      layout
      className='col-span-5 grid grid-cols-subgrid rounded-xl bg-surface-primary p-4 px-6'>
      <div className='flex w-full items-center gap-4'>
        <RenamableAlbum
          albumId={albumId}
          className='flex-row-reverse gap-2 bg-transparent p-0 text-white'
        />
      </div>
      <div className='flex items-center gap-3 text-slate-200/60'>
        <div className=''>{time}</div>
        <FontAwesomeIcon icon={faClockRotateLeft} />
      </div>
      <div className=''>{description}</div>
      <div className=''>{images.length}</div>
      <div
        className='cursor-pointer place-self-end'
        onClick={() => {
          if (album.status === 'archived') {
            restoreAlbum(album, album.id);
          } else {
            archiveAlbum(album, album.id);
          }
        }}>
        <FontAwesomeIcon
          icon={album.status === 'archived' ? faTrashRestore : faTrashCan}
        />
      </div>
    </motion.div>
  );
};

export default AlbumList;
