import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faClock,
  faTimesCircle,
  faPalette,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import InfoTag from '../../lib/InfoTag';
import { useCurrentCreationId } from '../store/currentCreationIdStore';
import useGetCreation from '../../../hooks/creations/useGetCreation';
import { CreationImage } from './CreationImage';
import AlbumName from '../Albums/AlbumName';
import Card from '@/components/lib/Containers/Card';

const { Section, SectionHeader, SubSection, SubSectionHeader } = Card;

export const CreationView = () => {
  const { creationId, setCreationId } = useCurrentCreationId();
  const { creation } = useGetCreation(creationId as string);
  console.log(creation);
  if (creationId === null) return null;
  if (creation == null) return null;
  const imagesIds = ['0', '1', '2', '3'];

  const day = new Date(creation.createdAt).toLocaleDateString(undefined, {
    month: 'numeric',
    day: 'numeric',
  });

  const hour = new Date(creation.createdAt).toLocaleTimeString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <Card>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-3 text-xs'>
          <InfoTag>
            <FontAwesomeIcon icon={faCalendar} />
            {day}
          </InfoTag>
          <InfoTag>
            <FontAwesomeIcon icon={faClock} />
            {hour}
          </InfoTag>
        </div>
        <Button
          rightIcon={faTimesCircle}
          iconSize='10px'
          variant='primary'
          size='xs'
          className='text-xs'
          round
          onClick={() => setCreationId(null)}>
          Fermer
        </Button>
      </div>
      <div className='grid grid-cols-2 gap-3'>
        {imagesIds.map((imageId, index) => {
          return (
            <CreationImage
              key={index}
              imageId={imageId}
              creationId={creationId}
              small={false}
            />
          );
        })}
      </div>

      {/* DESCRIPTION */}
      <Section>
        <SectionHeader>Description</SectionHeader>
        <p className='text-sm'>{creation.input}</p>
      </Section>

      {/* STATS */}
      <Section>
        <SectionHeader>Styles</SectionHeader>

        <div className='flex gap-4 text-xs'>
          <SubSection>
            <SubSectionHeader>Style</SubSectionHeader>
            <InfoTag>
              <FontAwesomeIcon icon={faPalette} />
              {creation.graphicStyle}
            </InfoTag>
          </SubSection>

          {creation.inspire && (
            <>
              <SubSection>
                <SubSectionHeader>Album</SubSectionHeader>
                <InfoTag>
                  <FontAwesomeIcon icon={faBookOpen} />
                  <AlbumName albumId={creation.inspire.albumId} />
                </InfoTag>
              </SubSection>
              <SubSection>
                <SubSectionHeader>Liberté</SubSectionHeader>
                <InfoTag>{creation.inspire.weight}</InfoTag>
              </SubSection>
            </>
          )}
        </div>
      </Section>
    </Card>
  );
};
