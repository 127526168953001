
import { Loader2 } from 'lucide-react';
import useGetCreationImage from '../../../hooks/creations/useGetCreationImage';

type CreationImageProps = {
  imageId: string;
  creationId: string;
  small?: boolean;
};

export const CreationImage = ({ imageId, creationId, small }: CreationImageProps) => {
  const { image, isLoading } = useGetCreationImage(creationId, imageId, small);

  return (
    <div className='flex aspect-square w-full items-center justify-center overflow-hidden rounded-xl'>
      {isLoading ? (
        <Loader2 className='text-primary mx-auto animate-spin' size={40} />
      ) : (
        <img
          src={image}
          className='h-full w-full object-cover'
          alt='Creation'
        />
      )}
    </div>
  );
};