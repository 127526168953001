import { useKeycloak } from '@react-keycloak/web';
import { capitalizeFirstLetter } from '../../../helpers/string';
import useGetCreations from '@/hooks/creations/useGetCreations';

import { UserInfo } from '../../../types/user';
import PageTitle from '../Layout/PageTitle';

import HorizontalScroll from '../../lib/HorizontalScroll';
import Tabs from '@/lib/Tabs/Tabs';
import { faChalkboard, faFolder } from '@fortawesome/free-solid-svg-icons';
import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';

import { CreationCard } from '../Creations/CreationCard';
import useGetProjects from '@/hooks/projects/useGetProjects';
import { ProjectCard } from '../Projects/ProjectCard';
import { WhiteboardCard } from '../Whiteboards/WhiteboardCard';

const Homepage = () => {
  const { creations } = useGetCreations();
  const { keycloak } = useKeycloak();
  const userInfo = keycloak.userInfo as UserInfo;

  const title = 'Bonjour ' + capitalizeFirstLetter(userInfo?.given_name);

  return (
    <div className='flex h-full w-full flex-col gap-4 bg-surface-secondary'>
      <PageTitle title={title} />

      <Tabs
        tabs={[
          {
            id: 'recent',
            title: 'Tableaux récents',
            content: <RecentWhiteboards key='recent-whiteboards' />,
            icon: faChalkboard,
          },
          {
            id: 'popular',
            title: 'Projet récents',
            content: <RecentProjects />,
            icon: faFolder,
          },
        ]}
      />

      <h2>Création récentes</h2>
      <HorizontalScroll>
        <div className='flex w-min gap-4'>
          {creations.slice(0, 10).map((creation) => (
            <div key={creation.id} className='w-[250px] overflow-hidden'>
              <CreationCard creationId={creation.id} />
            </div>
          ))}
        </div>
      </HorizontalScroll>
    </div>
  );
};

const RecentWhiteboards = () => {
  const { whiteboards } = useGetWhiteboards('visible');
  if (!whiteboards) return null;

  return (
    <HorizontalScroll>
      <div className='flex w-min gap-4'>
        {whiteboards.map((whiteboard) => (
          <WhiteboardCard
            width={'min-content'}
            key={whiteboard.id}
            whiteboardId={whiteboard.id}
          />
        ))}
      </div>
    </HorizontalScroll>
  );
};

const RecentProjects = () => {
  const { projects } = useGetProjects();
  if (!projects) return null;

  return (
    <HorizontalScroll>
      <div className='flex w-min gap-4'>
        {projects.map((project) => (
          <ProjectCard projectId={project.id} key={project.id} />
        ))}
      </div>
    </HorizontalScroll>
  );
};

export default Homepage;
