import React, { useRef } from 'react';
import useClickOutside from '@/hooks/useClickOutside';
import { motion } from 'framer-motion';

type SidepanelProps = {
  children: React.ReactNode;
  closeFn?: () => void;
};

const Sidepanel = ({ children, closeFn }: SidepanelProps) => {
  const ref = useRef(null);
  useClickOutside(ref, closeFn || (() => {}));

  return (
    <motion.div
      initial={{ backdropFilter: 'blur(0px)' }}
      animate={{ backdropFilter: 'blur(25px)' }}
      exit={{ backdropFilter: 'blur(0px)' }}
      transition={{ duration: 0.1 }}
      className='absolute inset-0 left-0 top-0 z-50 flex items-center justify-end'>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ duration: 0.1 }}
        ref={ref}
        className='p-10'>
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Sidepanel;
