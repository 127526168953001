import { motion } from 'framer-motion';

import PanelContent from '../PanelContent';
import MobilePanelHeader from './MobilePanelHeader';
import { usePanelContext } from '../context/usePanelContext';

const MobilePanel = () => {
  //match xs breakpoint

  const { isPanelOpen } = usePanelContext();
  return (
    <motion.div
      layout
      className='pointer-events-none flex h-full w-full items-end justify-center'>
      <motion.div
        layout
        style={{ borderTopLeftRadius: '26px', borderTopRightRadius: '26px' }}
        className={`${isPanelOpen ? 'h-[90%]' : 'h-min'} relative w-min overflow-hidden bg-d-surface-bg-base-blur backdrop-blur-sm`}>
        {isPanelOpen ? <PanelContent /> : <MobilePanelHeader />}
      </motion.div>
    </motion.div>
  );
};

export default MobilePanel;
