import {
  BinaryFileData,
  BinaryFiles,
  DataURL,
  ExcalidrawImperativeAPI,
  ExcalidrawInitialDataState,
} from '@excalidraw/excalidraw/types/types';
import { useStore } from '@nanostores/react';
import { useEffect, useRef, useState } from 'react';
import { $excalidrawState, $excalidrawElements } from './excalidraw';
import {
  ExcalidrawElement,
  ExcalidrawImageElement,
  FileId,
} from '@excalidraw/excalidraw/types/element/types';
import { throttle } from 'lodash';
import {
  AlreadyExistsError,
  useWhiteboardAPI,
} from '../hooks/whiteboard/useWhiteboardAPI';
import {
  exportToBlob,
  restoreAppState,
  restoreElements,
} from '@excalidraw/excalidraw';
import {
  WHITEBOARD_PREVIEW_IMAGE_INTERVAL_SECONDS,
  WHITEBOARD_SAVE_INTERVAL_SECONDS,
} from '../helpers/FeatureFlags';
import { useQueryClient } from 'react-query';

let db: IDBDatabase;
const dbName = 'files-db';
const storeName = 'files-store';

let savedFilesIds: string[] = [];

type ExcalidrawData = {
  state: any;
  elements: any;
  files: BinaryFiles;
};

// Note for dev: DB can be reset with indexedDB.deleteDatabase(dbName)

export const initFilesDB = (): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }

      // no need to resolve here
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(true);
    };

    request.onerror = (err) => {
      console.warn(`failed to init files DB: ${err}`);
      resolve(false);
    };
  });
};

export const useInitIndexedDB = () => {
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  useEffect(() => {
    initFilesDB()
      .then((status) => setIsDBReady(status))
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  return isDBReady;
};

function blobToDataURL(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result); // Data URL
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob); // Read blob as data URL
  });
}

export const useGetWhiteboardData = (whiteboardId: string) => {
  const { getWhiteboardData, getWhiteboardImage } =
    useWhiteboardAPI();
  const [excalidrawData, setExcalidrawData] = useState<ExcalidrawData>();

  useEffect(() => {
    console.debug('load data from whiteboard', whiteboardId);
    savedFilesIds = [];
    // Current whiteboard exists: load its data
    getWhiteboardData(whiteboardId)
      .then((data) => {
        if (!data.data) return;

        if (!data.images.length) {
          setExcalidrawData({
            state: data.data.state,
            elements: data.data.elements,
            files: {},
          });

          return;
        }

        // Download each image file
        const files: BinaryFiles = {};
        Promise.all(
          data.images.map(async (image) => {
            const blob = await getWhiteboardImage(whiteboardId, image.id);
            const dataURL = (await blobToDataURL(blob)) as DataURL;
            files[image.id] = {
              id: image.id as FileId,
              mimeType: image.mimeType,
              dataURL: dataURL,
              created: new Date().getTime(),
            } as BinaryFileData;

            // Save to local list of files IDs
            savedFilesIds.push(image.id);
          })
        ).then(() => {
          setExcalidrawData({
            state: data.data.state,
            elements: data.data.elements,
            files: files,
          });
        });
      })
      .catch((error) => {
        console.error(`failed to get current whiteboard saved data: ${error}`);
      });
  }, []);

  return excalidrawData;
};

export const useGetExcalidrawInitialData = (whiteboardId: string, loadDataFromLocal: boolean) => {
  console.debug('get excalidraw initial data');
  const [excalidrawInitialData, setExcalidrawInitialData] =
    useState<ExcalidrawInitialDataState | null>(null);

  const excalidrawData = useGetWhiteboardData(whiteboardId);

  const getInitialDataFromServer = async (): Promise<void> => {
    if (!excalidrawData) return;

    console.debug('get whiteboard data from server');
    console.debug('state from API', excalidrawData.state);
    console.debug('elements from API', excalidrawData.elements);

    setExcalidrawInitialData({
      appState: restoreAppState(excalidrawData.state, null),
      elements: restoreElements(excalidrawData.elements, null) || [],
      files: excalidrawData.files,
    });
  };

  useEffect(() => {
    if (!loadDataFromLocal) getInitialDataFromServer();
  }, [excalidrawData, loadDataFromLocal]);

  // Load from local
  const isDBReady = useInitIndexedDB();
  const excalidrawState = useStore($excalidrawState);
  const excalidrawElements = useStore($excalidrawElements);

  const getInitialDataFromLocal = async (): Promise<void> => {
    // No state saved, no initial data
    if (!excalidrawState || !isDBReady || !loadDataFromLocal) return;

    console.debug('get whiteboard data from local');

    const appState = JSON.parse(excalidrawState);
    appState.collaborators = []; // Prevent "Unexpected Application Error! collaborators.forEach is not a function"
    const elements = JSON.parse(excalidrawElements);

    const storedFiles = await getFiles();

    //if (storedFiles) savedFilesIdsLocal = Object.keys(storedFiles);

    setExcalidrawInitialData({
      appState: appState,
      elements: elements || [],
      files: storedFiles || {},
    });
  };

  useEffect(() => {
    if (loadDataFromLocal) getInitialDataFromLocal();
  }, [isDBReady, loadDataFromLocal]);

  return excalidrawInitialData;
};

export const getFiles = (): Promise<BinaryFiles> => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result[0]);
      };
    };
  });
};

// useSaveExcalidraw return a throttled ref with save() and cancel() functions.
// Note: the throttled function is recreated whenever currentWhiteboard changes
// to ensure it always uses the latest value.
export const useSaveExcalidraw = (
  excalidrawAPI: ExcalidrawImperativeAPI | undefined,
  whiteboardId: string
) => {
  const { saveWhiteboardData, addWhiteboardImage } = useWhiteboardAPI();
  const throttledSaveRef = useRef<ReturnType<typeof throttle> | null>(null);

  useEffect(() => {
    // Recreate the throttled function whenever `currentWhiteboard` changes
    throttledSaveRef.current = throttle(async () => {
      console.debug('current wb', whiteboardId);
      if (!excalidrawAPI || !whiteboardId) return;

      console.debug('save excalidraw data to server triggered');
      const elements = excalidrawAPI.getSceneElements();

      // Important: if the save is triggered before Excalidraw is fully
      // loaded the save could be erased without this test
      if (!elements || !elements.length) {
        console.debug('no elements, no save');
        return;
      }

      const appState = excalidrawAPI.getAppState();
      // @ts-ignore
      appState.collaborators = []; // Prevent the "collaborators.forEach is not a function" error
      const allFiles = excalidrawAPI.getFiles();

      const createFilesIds: string[] = [];
      const keepFilesIds: string[] = [];

      elements
        .filter((element: ExcalidrawElement) => element.type == 'image')
        .forEach((element: ExcalidrawElement) => {
          const image = element as ExcalidrawImageElement;
          const fileId = image.fileId as string;
          if (fileId) {
            if (!savedFilesIds.includes(fileId)) {
              const base64 = allFiles[fileId]?.dataURL?.split(',')?.[1];
              if (base64) {
                addWhiteboardImage(
                  fileId,
                  whiteboardId,
                  base64,
                  allFiles[fileId].mimeType
                )
                  .then(() => {
                    createFilesIds.push(fileId);
                  })
                  .catch((err) => {
                    // Ignore already exist error
                    if (err! instanceof AlreadyExistsError) {
                      console.error(`failed to save whiteboard image: ${err}`);
                    } else {
                      console.debug('whiteboard image already exists');
                    }
                  });
              }
            } else {
              keepFilesIds.push(fileId);
            }
          }
        });

      const deleteFiles: string[] = [];
      savedFilesIds.forEach((id) => {
        if (!keepFilesIds.includes(id)) {
          deleteFiles.push(id);
        }
      });

      const data = {
        state: appState,
        elements: elements,
      };

      saveWhiteboardData(whiteboardId, data, deleteFiles)
        .then(() => {
          savedFilesIds = [...savedFilesIds, ...createFilesIds];
          if (createFilesIds.length) console.debug('files saved on server');
        })
        .catch((err: any) => {
          // XXX: maybe handle this error later by alerting the user and
          // inviting them to manually save Excalidraw
          console.warn('save data on server error:', err);
        });
    }, WHITEBOARD_SAVE_INTERVAL_SECONDS);
  }, [excalidrawAPI, whiteboardId]);

  // Return a callable save function that always uses the latest throttled function
  return {
    save: () => {
      if (throttledSaveRef.current) {
        throttledSaveRef.current();
      }
    },
    cancel: () => {
      // Reset saved files IDs
      throttledSaveRef.current?.cancel();
    },
  };
};

// useSavePreviewImage returns a function that allow to save a preview image
// of the whiteboard
export const useSavePreviewImage = (
  excalidrawAPI: ExcalidrawImperativeAPI | undefined,
  whiteboardId: string,
) => {
  const { saveWhiteboardPreviewImage } = useWhiteboardAPI();
  const queryClient = useQueryClient();
  const throttledSaveRef = useRef<ReturnType<typeof throttle> | null>(null);

  useEffect(() => {
    // Recreate the throttled function whenever `currentWhiteboard` changes
    throttledSaveRef.current = throttle(async () => {
      console.debug('current wb', whiteboardId);
      if (!excalidrawAPI || !whiteboardId) return;

      console.debug('save preview image to server triggered');
      const elements = excalidrawAPI.getSceneElements();

      if (!elements || !elements.length) {
        console.debug('no elements, no preview image');
        return;
      }

      const imageBlob = await exportToBlob({
        elements: elements,
        files: excalidrawAPI.getFiles(),
        mimeType: 'image/jpeg',
        quality: 0.5,
      });

      saveWhiteboardPreviewImage(whiteboardId, imageBlob).catch((err) => {
        console.warn(`failed to save whiteboard preview image: ${err}`);
      });
    }, WHITEBOARD_PREVIEW_IMAGE_INTERVAL_SECONDS);
  }, [excalidrawAPI, whiteboardId]);

  // Return a callable save function that always uses the latest throttled function
  return {
    save: () => {
      if (throttledSaveRef.current) {
        throttledSaveRef.current();
        queryClient.invalidateQueries(['whiteboardImage', whiteboardId]);
      }
    },
    cancel: () => {
      throttledSaveRef.current?.cancel();
    },
  };
};
