import { useNavigate, useParams } from 'react-router-dom';
import ExcalidrawWhiteboard from '../components/Creator/ExcalidrawWhiteboard';
import { AlbumProvider } from '../components/Creator/Panel/context/AlbumContext';
import { PanelProvider } from '../components/Creator/Panel/context/PanelContext';
import { PanelInputProvider } from '../components/Creator/Panel/context/PanelInputContext';
import Panel from '../components/Creator/Panel/Panel';
import { useCurrentWhiteboard } from '../store/currentWhiteboard';
import { AlreadyExistsError, useWhiteboardAPI } from '../hooks/whiteboard/useWhiteboardAPI';
import { useEffect } from 'react';

const Creator = () => {
  const navigate = useNavigate();
  const { createWhiteboard } = useWhiteboardAPI();
  const params = useParams();
  const { whiteboardId: localWhiteboardId, setWhiteboardId } = useCurrentWhiteboard();
  const queryParams = new URLSearchParams(location.search);
  let loadDataFromLocal = queryParams.get('loadDataFromLocal') === 'true';
  let whiteboardId = params['whiteboardId'];
  // No whiteboard ID in as path param, try to get it from
  // local storage
  useEffect(() => {
    if (whiteboardId) {
      setWhiteboardId(whiteboardId);
      return;
    }

    // No whiteboard from URL or local storage, create a new one
    if (!localWhiteboardId) {
      // Create a default whiteboard
      console.debug('create default whiteboard');
      createWhiteboard(
        'whiteboard ' +
        new Date().toLocaleDateString() +
          ' - ' +
          new Date().toLocaleTimeString()
      ).then((whiteboard) => {
        console.debug('created whiteboard:', whiteboard);

        // Redirect to the new whiteboard with load data from
        // local parameter
        navigate(`/creator/${whiteboard.id}?loadDataFromLocal=true`);
        return;
      }).catch((err) => {
        // Ignore already exists error
        if (err instanceof AlreadyExistsError) {
          console.error(`failed to create default whiteboard: ${err}`);
        } else {
          console.debug('whiteboard already exists');
        }
      });
    }

    navigate(`/creator/${localWhiteboardId}`);
    return;
  }, []);

  if (!whiteboardId) return;
  console.debug("FROM LOCAL PARAM", loadDataFromLocal);

  return (
    <AlbumProvider>
      <PanelProvider>
        <PanelInputProvider>
          <ExcalidrawWhiteboard whiteboardId={whiteboardId} loadDataFromLocal={loadDataFromLocal}>
            <Panel />
          </ExcalidrawWhiteboard>
        </PanelInputProvider>
      </PanelProvider>
    </AlbumProvider>
  );
};

export default Creator;
