import { Dispatch, SetStateAction, useState, createContext } from 'react';
import { DesignStyle } from '../../../../types';

type PanelInputContextType = {
  imagineInputText: string;
  setImagineInputText: Dispatch<SetStateAction<string>>;
  inpaintInputText: string;
  setInpaintInputText: Dispatch<SetStateAction<string>>;
  graphicStyle: DesignStyle | null;
  setGraphicStyle: Dispatch<SetStateAction<DesignStyle | null>>;
  albumCursor: number;
  setAlbumCursor: Dispatch<SetStateAction<number>>;
};

export const PanelInputContext = createContext<
  PanelInputContextType | undefined
>(undefined);

export const PanelInputProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [imagineInputText, setImagineInputText] = useState<string>('');
  const [inpaintInputText, setInpaintInputText] = useState<string>('');
  const [graphicStyle, setGraphicStyle] = useState<DesignStyle | null>(null);

  const [albumCursor, setAlbumCursor] = useState<number>(0);

  return (
    <PanelInputContext.Provider
      value={{
        imagineInputText,
        setImagineInputText,
        inpaintInputText,
        setInpaintInputText,
        graphicStyle,
        setGraphicStyle,
        albumCursor,
        setAlbumCursor,
      }}>
      {children}
    </PanelInputContext.Provider>
  );
};
