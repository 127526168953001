import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import AlbumImage from '../../Creator/Panel/Album/AlbumImage';

import { LayoutGroup, motion } from 'framer-motion';

import useGetAlbum from '../../../hooks/albums/useGetAlbum';
import { TimeTags } from '../Creations/CreationCard';
import RenamableAlbum from '../../Creator/Panel/Album/RenamableAlbum';
import Button from '../../lib/Button';
import { useCurrentAlbumId } from '../store/currentAlbumIdStore';

type AlbumGridProps = {
  albums: {
    id: string;
    name: string;
  }[];
};

const AlbumGrid = ({ albums }: AlbumGridProps) => {
  if (!albums) {
    return <p>No albums found</p>;
  }

  return (
    <div className='grid grid-flow-dense grid-cols-2 gap-4 overflow-y-auto xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'>
      <LayoutGroup>
        {albums.map((album) => {
          return <AlbumCard key={album.id} albumId={album.id} />;
        })}
      </LayoutGroup>
    </div>
  );
};

type AlbumViewProps = {
  albumId: string;
};

const AlbumCard = ({ albumId }: AlbumViewProps) => {
  const { album } = useGetAlbum(albumId);
  const { setCurrentAlbumId } = useCurrentAlbumId();

  if (!album) return null;
  const { images, id, createdAt } = album;

  const moreThanFourImages = images.length > 4;

  return (
    <motion.div
      layoutId={id}
      layout
      className={`flex flex-col gap-4 rounded-2xl bg-surface-primary p-4`}>
      {/* IMAGES */}
      <div className='flex flex-col gap-2'>
        <motion.div layout className={`grid w-full grid-cols-4 gap-2`}>
          {images
            .slice(0, moreThanFourImages ? 3 : 4)
            .map((image: { id: string }) => {
              return (
                <motion.div
                  layout
                  layoutId={image.id}
                  key={image.id}
                  className=''>
                  <AlbumImage albumId={album.id} imageId={image.id} />
                </motion.div>
              );
            })}
          {moreThanFourImages && (
            <motion.div
              layout
              layoutId={images[3].id}
              className='relative overflow-hidden rounded-2xl'>
              <AlbumImage albumId={album.id} imageId={images[3].id} />
              <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-slate-800 bg-opacity-70'>
                <div className='text-2xl font-bold text-white'>
                  +{images.length - 3}
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
        <p className='text-xs opacity-50'>
          {images.length} images dans l'album
        </p>
      </div>

      <div className='flex justify-between text-sm'>
        <h1 className='flex items-center justify-center gap-1'>
          <FontAwesomeIcon icon={faBookOpen} />
          <RenamableAlbum
            key={album.name}
            albumId={album.id}
            className='gap-2 bg-transparent p-0 text-white'
          />
        </h1>
      </div>
      <div className='flex items-center justify-between'>
        <TimeTags createdAt={createdAt} />
        <Button
          size={'xs'}
          onClick={() => {
            setCurrentAlbumId(albumId);
          }}>
          Détails
        </Button>
      </div>
    </motion.div>
  );
};

export default AlbumGrid;
