import { Eye, EyeOff } from 'lucide-react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import useTimeAgo from '../../../../hooks/useTimeAgo';
import ResultsGenerationImage from './ResultsGenerationImage';

import { DesignStyle, GenerationFromAPI } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

type ExpandableCardProps = {
  id: number; // Identifiant unique de la carte
  initialExpand: boolean;
  isLoading?: boolean;
  generation: GenerationFromAPI;
  activeCard: number | null; // ID de la carte active
  setActiveCard: (id: number | null) => void; // Fonction pour définir la carte active
};

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  id,
  initialExpand = false,
  isLoading = false,
  generation,
  activeCard,
  setActiveCard,
}) => {
  const { id: genId, input, graphicStyle, createdAt } = generation;
  const imagesIds = ["0", "1", "2", "3"];
  const { t } = useTranslation(['panel']);

  // Si `initialExpand` est vrai, ouvrir la carte au premier rendu
  useEffect(() => {
    if (initialExpand) {
      setActiveCard(id);
    }
  }, [initialExpand, id, setActiveCard]);

  // Déterminer si la carte est actuellement ouverte en fonction de `activeCard`
  const isExpanded = activeCard === id;

  const date = new Date(createdAt);
  const timeAgo = useTimeAgo(date);

  const SUGGESTIONS: Record<DesignStyle, string> = {
    [DesignStyle.Industrial]: t('Imagine.DesignStyle.Industrial'),
    [DesignStyle.Line]: t('Imagine.DesignStyle.Line'),
    [DesignStyle.Davinci]: t('Imagine.DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('Imagine.DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('Imagine.DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('Imagine.DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('Imagine.DesignStyle.Photorealistic'),
  };

  const graphicStyleTranslated = SUGGESTIONS[graphicStyle as DesignStyle];

  return (
    <motion.div
      layout
      style={{ borderRadius: '10px' }}
      className={`flex w-full flex-col p-3 ${
        isExpanded
          ? 'border border-d-surface-bg-secondary'
          : 'bg-d-surface-bg-tertiary-blur opacity-100'
      } text-text-color`}>
      {/* HEADER DE LA CARTE */}
      <motion.div
        layout
        className='flex w-full items-center justify-between gap-2 px-1 py-2'>
        <motion.div layout className='flex items-center gap-2'>
          <FontAwesomeIcon icon={faClock} width={14} height={14} />
          <span className='text-xs'>{timeAgo}</span>
        </motion.div>
        {isExpanded ? (
          <EyeOff
            width={16}
            height={16}
            className='cursor-pointer'
            onClick={() => setActiveCard(null)} // Fermer la carte en réinitialisant `activeCard`
          />
        ) : (
          <Eye
            width={16}
            height={16}
            className='cursor-pointer'
            onClick={() => setActiveCard(id)} // Ouvrir cette carte en définissant `activeCard` à son ID
          />
        )}
      </motion.div>

      <AnimatePresence mode='popLayout'>
        {isExpanded && (
          <motion.div layout className='flex w-full flex-col gap-2 pt-3'>
            {/* IMAGES */}
            <motion.div
              layout
              className='grid h-min w-full max-w-[400px] grid-cols-2 justify-center gap-3 place-self-center'>
              {imagesIds.map((imageId, index) => (
                <motion.div
                  layout
                  className={
                    'aspect-[16/11] w-full cursor-pointer overflow-hidden rounded-lg'
                  }
                  key={index}>
                  <ResultsGenerationImage
                    isLoading={isLoading}
                    imagePath={`/generation/${genId}/image/${imageId}`}
                    className='h-full w-full object-cover'
                  />
                </motion.div>
              ))}
            </motion.div>

            {/* PROMPT*/}
            <motion.div layout className='flex flex-col gap-4 rounded-xl p-3'>
              <motion.div layout className='flex flex-col items-start gap-0'>
                <p className='flex gap-0 font-jakarta text-xs text-d-text-2'>
                  {t('panel:GenerationCard.prompt')}
                </p>
                <p className='text-d-text-negative rounded-md text-base'>
                  {input}
                </p>
              </motion.div>
              {/* GRAPHIC STYLE */}
              <div className='flex flex-col gap-0 text-xs text-d-text-2'>
                <p className='flex items-center gap-0 font-jakarta'>
                  {t('panel:GenerationCard.style')}
                </p>
                <p className='text-base text-d-text-neg'>
                  {graphicStyleTranslated}
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
