import { atom, useAtom } from 'jotai';

export const currentAlbumIdAtom = atom<string | null>(null);

export function useCurrentAlbumId() {
  const [currentAlbumId, setCurrentAlbumId] = useAtom(currentAlbumIdAtom);

  return {
    currentAlbumId,
    setCurrentAlbumId,
  };
}
