import Fuse from 'fuse.js';
import { useState, useMemo } from 'react';
import { Option } from '../types/dropdown.types';

export const useDropdownSearch = (
  options: Option[],
  maximumVisibleOptions: number
) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const fuseOptions = {
    keys: ['label'],
    threshold: 0.6, //The higher the more matches
    shouldSort: true,
  };
  const fuse = useMemo(() => new Fuse(options, fuseOptions), [options]);

  const filteredOptions = useMemo(() => {
    //If there's no search value, just return the options
    if (searchValue.length === 0) {
      return options
        .sort((a, b) =>
          a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1
        )
        .slice(0, maximumVisibleOptions);
    }

    return fuse
      .search(searchValue)
      .map((result) => result.item)
      .slice(0, maximumVisibleOptions);
  }, [searchValue, options, maximumVisibleOptions]);

  return { searchValue, setSearchValue, filteredOptions };
};
