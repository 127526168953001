import { Loader2 } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import useGetCreations from '../../../hooks/creations/useGetCreations';
import PageTitle from '../Layout/PageTitle';

import { CreationCard } from './CreationCard';

const Creations = () => {
  const { ref, inView } = useInView();

  const {
    creations,
    isLoading,
    isError,

    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCreations();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;
  if (!creations?.length) return <div>No creations found</div>;

  return (
    <div className='flex h-full flex-col gap-3'>
      <PageTitle title='Créations' />
      <div className='grid grid-cols-3 gap-4 overflow-y-auto'>
        {creations.map((creation) => (
          <CreationCard key={creation.id} creationId={creation.id} />
        ))}
        <div ref={ref} className='col-span-3 flex justify-center p-4'>
          {isFetchingNextPage && <Loader2 className='animate-spin' />}
        </div>
      </div>
    </div>
  );
};

export default Creations;
