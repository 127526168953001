import Dropdown from '../../../../lib/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { DesignStyle } from '../../../../../types';
import { usePanelInputContext } from '../../context/usePanelInputContext';
import { faPalette, faTimes } from '@fortawesome/free-solid-svg-icons';

import { DropdownElementProps } from '../../../../lib/Dropdown/types/dropdown.types';
import SimpleButton from '../../../../lib/SimpleButton';

const GraphicStyleElement = ({
  isSelected,
  reset,
  value,
}: DropdownElementProps & { value: DesignStyle }) => {
  const { setGraphicStyle } = usePanelInputContext();
  const { t } = useTranslation(['panel']);
  return (
    <div className='flex items-center gap-2'>
      <div className='aspect-square rounded-md bg-white p-2'>
        <SimpleButton
          className='text-text-negative'
          size='squareXXS'
          leftIcon={isSelected ? faTimes : faPalette}
          onClick={() => {
            if (isSelected && reset) {
              setGraphicStyle(null);
              reset();
            }
          }}
        />
      </div>
      <div className=''>{t(`panel:Imagine.DesignStyle.${value}`)}</div>
    </div>
  );
};

const GraphicStyleDropdown = () => {
  const { t } = useTranslation(['panel']);
  const { setGraphicStyle, graphicStyle } = usePanelInputContext();

  const SUGGESTIONS: Record<DesignStyle, string> = {
    [DesignStyle.Industrial]: t('panel:Imagine.DesignStyle.Industrial'),
    [DesignStyle.Line]: t('panel:Imagine.DesignStyle.Line'),
    [DesignStyle.Davinci]: t('panel:Imagine.DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('panel:Imagine.DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('panel:Imagine.DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('panel:Imagine.DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('panel:Imagine.DesignStyle.Photorealistic'),
  };

  const options = Object.entries(SUGGESTIONS).map(([key, value]) => ({
    id: key,
    label: value,
    element: (props: DropdownElementProps) => (
      <GraphicStyleElement {...props} value={key as DesignStyle} />
    ),
  }));

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={
          graphicStyle && {
            id: graphicStyle,
            label: t(`panel:Imagine.DesignStyle.${graphicStyle}`),

            element: (props: DropdownElementProps) => (
              <GraphicStyleElement {...props} value={graphicStyle} />
            ),
          }
        }
        Header={() => (
          <div className='p-2 px-4 font-semibold'>
            {t('panel:Generate.graphicStyleLabel')}
          </div>
        )}
        options={options}
        icon={faPalette}
        isContentContained={false}
        placeholder={t('panel:Generate.graphicStyleLabel')}
        showSelectedWithinList={true}
        onChange={(option) => {
          setGraphicStyle(option ? (option.id as DesignStyle) : null);
          console.log(option);
        }}
      />
    </div>
  );
};

export default GraphicStyleDropdown;
