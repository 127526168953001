import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { ProtectedLayout } from '../helpers/ProtectedLayout';

import NotFoundPage from './NotFound';

import { useKeycloak } from '@react-keycloak/web';

import Creator from './Creator';
import Layout from '../components/Dashboard/Layout/Layout';
import Homepage from '../components/Dashboard/Homepage/Homepage';
import Projects from '../components/Dashboard/Projects/Projects';
import Whiteboards from '../components/Dashboard/Whiteboards/Whiteboards';
import Albums from '../components/Dashboard/Albums/Albums';

import {
  faBookOpen,
  faChalkboard,
  faHome,
  faImages,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import Creations from '../components/Dashboard/Creations/Creations';

export type RouteType = {
  path: string;
  icon: IconDefinition;
  label: string;
  component: ReactNode;
  index?: boolean;
  hideInSidebar?: boolean;
};

export const dashboardRoutes: Array<RouteType> = [
  {
    path: '/',
    icon: faHome,
    label: 'Ma Page',

    component: <Homepage />,
    index: true,
  },
  {
    path: '/creation',
    icon: faImages,
    label: 'Créations',
    component: <Creations />,
  },
  {
    path: '/projects',
    icon: faImages,
    label: 'Projets',

    component: <Projects />,
  },
  {
    path: '/whiteboards',
    icon: faChalkboard,
    label: 'Tableaux',

    component: <Whiteboards />,
  },
  {
    path: '/albums',
    icon: faBookOpen,
    label: 'Albums',
    component: <Albums />,
  },
] as const;
export const allRoutes = [...dashboardRoutes] as const;

export type Path = (typeof allRoutes)[number]['path'];

const router = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<ProtectedLayout />}>
          <Route element={<Layout />}>
            {dashboardRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}

            <Route path='*' element={<NotFoundPage />} />
          </Route>
          <Route path='/creator/:whiteboardId' element={<Creator />} />
          <Route path='/creator' element={<Creator />} />
        </Route>

        <Route path='*' element={<NotFoundPage />}></Route>
      </>
    )
  );
const Router = () => {
  const { initialized } = useKeycloak();
  if (!initialized) return;

  return <RouterProvider router={router()} />;
};

export default Router;
