import { motion } from 'framer-motion';
import { TextareaHTMLAttributes } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea = ({ label, ...props }: TextAreaProps) => {
  return (
    <motion.div layout className='flex flex-col gap-4'>
      {label && (
        /* TODO: extract label component */
        <motion.div
          layout
          className='font-jakarta text-sm text-d-text-2 excalidraw:pl-2'>
          {label}
        </motion.div>
      )}
      <motion.div layout>
        <textarea
          {...props}
          className='w-full resize-none appearance-none rounded-xl border-none
            bg-d-surface-bg-secondary p-3 text-sm font-medium text-d-text-neg shadow-none
            focus:border-none focus:ring-green-400'
          rows={4}
        />
      </motion.div>
    </motion.div>
  );
};

export default TextArea;
