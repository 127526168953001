import { useQuery } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';
import { AlbumShort } from '../../types/api';

const useGetAlbums = (status?: 'archived' | 'visible') => {
  const { getAlbums } = useAlbumAPI();

  const { data, isLoading, isError, error } = useQuery<AlbumShort[], Error>({
    queryKey: ['albums', status],
    queryFn: async () => getAlbums(status),
  });

  return {
    albums: data,
    isLoading,
    isError,
    error,
  };
};

export default useGetAlbums;
