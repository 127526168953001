import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { BeinkPhase } from '../../../../types';
import SimpleButton from '../../../lib/SimpleButton';

import PanelNavigation from '../PanelNavigation';
import GenerateButton from '../GenerateButton';
import { usePanelContext } from '../context/usePanelContext';

const MobilePanelHeader = () => {
  const { isPanelOpen, setIsPanelOpen, setPhase } = usePanelContext();
  return (
    <motion.div
      layout
      className='pointer-events-auto z-[1000] flex w-[380px] max-w-[100%] items-center justify-between p-4'>
      {/* REDUCE BUTTON */}
      <motion.div
        layout
        className='items-centers flex'
        animate={{ rotate: isPanelOpen ? 0 : 180 }}>
        <SimpleButton
          round
          theme='flat'
          variant='tertiary'
          size='squareMD'
          iconSize='16px'
          leftIcon={faAnglesDown}
          onClick={() => {
            setIsPanelOpen((prev) => !prev);

            setPhase(BeinkPhase.Generate);
          }}
        />
      </motion.div>
      <PanelNavigation />
      <GenerateButton />
    </motion.div>
  );
};

export default MobilePanelHeader;
