import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useTimeAgo = (date: Date | undefined) => {
  const { i18n } = useTranslation();
  const rtf = new Intl.RelativeTimeFormat(i18n.language, {
    numeric: 'always',
    style: 'short',
  });

  const getTimeAgo = useCallback(
    (date: Date) => {
      const now = new Date();
      const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

      let value, unit: Intl.RelativeTimeFormatUnit;
      if (diffInSeconds < 60) {
        value = diffInSeconds;
        unit = 'second';
      } else if (diffInSeconds < 3600) {
        value = Math.floor(diffInSeconds / 60);
        unit = 'minute';
      } else if (diffInSeconds < 86400) {
        value = Math.floor(diffInSeconds / 3600);
        unit = 'hour';
      } else {
        value = Math.floor(diffInSeconds / 86400);
        unit = 'day';
      }

      return rtf.format(-value, unit);
    },
    [i18n.language, rtf]
  );
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(date ?? new Date()));

  useEffect(() => {
    if (!date) return;

    setTimeAgo(getTimeAgo(date));

    const intervalId = setInterval(() => {
      setTimeAgo(getTimeAgo(date));
    }, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId);
  }, [date, i18n.language]);

  return timeAgo;
};

export default useTimeAgo;
