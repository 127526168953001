import { useQuery } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { WhiteboardShort } from '../../types/api';

export const useGetWhiteboards = (status: 'archived' | 'visible') => {
  const { getWhiteboards } = useWhiteboardAPI();

  const { data, isLoading, isError, error } = useQuery<
    WhiteboardShort[],
    Error
  >({
    queryKey: ['whiteboards', status],
    staleTime: 0,
    queryFn: async () => getWhiteboards(status),
  });

  return {
    whiteboards: data,
    isLoading,
    isError,
    error,
  };
};
