import { motion } from 'framer-motion';
import { Option, DropdownHeaderProps } from '../types/dropdown.types';

import DropdownSearch from './DropdownSearch';
import { useDropdownSearch } from '../hooks/useDropdownSearch';

type DropdownContentProps = {
  dropdownPosition: 'top' | 'bottom';
  Header?: React.FC<DropdownHeaderProps>;
  search?: boolean;
  options: Option[];
  selected: Option | null;
  showSelectedWithinList: boolean;
  onSelect: (option: Option) => void;
  onClose: () => void;
  onReset: () => void;
  maximumVisibleOptions: number;
};

const DropdownContent = ({
  dropdownPosition,
  Header,
  search,
  options,
  selected,
  showSelectedWithinList,
  onSelect,
  onClose,
  onReset,
  maximumVisibleOptions,
}: DropdownContentProps) => {
  const { searchValue, setSearchValue, filteredOptions } = useDropdownSearch(
    options,
    maximumVisibleOptions
  );

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      style={{ borderRadius: '12px' }}
      className={`absolute left-0 ${dropdownPosition === 'bottom' ? 'top-full' : 'bottom-full'} z-[4000] mb-2 mt-2 flex w-full flex-col gap-2 overflow-hidden rounded-xl bg-white py-2`}>
      {/* HEADER */}
      {Header && <Header close={onClose} reset={onReset} selected={selected} />}
      {/* SEARCH */}
      {search && (
        <DropdownSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
      <motion.div
        layout
        className='flex max-h-[250px] flex-col overflow-y-scroll bg-slate-100'>
        {/* SELECTED OPTIONS */}
        {selected && showSelectedWithinList && (
          <motion.div
            layout
            key={selected.id}
            className={
              'cursor-pointer bg-white p-2 text-text-negative hover:bg-d-surface-bg-tertiary hover:text-white'
            }>
            <selected.element
              reset={onReset}
              close={onClose}
              isSelected={true}
            />
          </motion.div>
        )}
        {/* OPTIONS */}
        {filteredOptions.map((option) => {
          if (selected?.id === option.id) return null;
          return (
            <motion.div
              layout
              key={option.id}
              className={
                'cursor-pointer p-2 text-text-negative hover:bg-d-surface-bg-tertiary hover:text-white'
              }
              onClick={() => onSelect(option)}>
              <option.element
                reset={onReset}
                close={onClose}
                isSelected={selected?.id === option.id}
              />
            </motion.div>
          );
        })}
      </motion.div>
    </motion.div>
  );
};

export default DropdownContent;
