import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type SidebarLinkProps = {
  to: string;
  children: React.ReactNode;
  icon: IconDefinition;
  activeSelectorId?: string;
  className?: string;
};

const SidebarLink = ({
  to,
  children,
  icon,
  activeSelectorId = 'active-selector',
  className,
}: SidebarLinkProps) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={twMerge(
        'relative flex items-center justify-start gap-3 rounded-xl p-3 text-base font-medium tracking-tighter',
        className
      )}>
      <FontAwesomeIcon icon={icon} className='z-10' />
      <div className='z-10 hover:opacity-75'>{children}</div>

      {isActive && (
        <motion.div
          layoutId={activeSelectorId}
          style={{ borderRadius: '12px' }}
          className='bg-surface-secondary absolute left-0 top-0 h-full w-full'></motion.div>
      )}
    </NavLink>
  );
};

export default SidebarLink;
