import { useMutation, useQueryClient } from 'react-query';
import { useProjectAPI } from './useProjectAPI';
import { Project } from '@/types/api';

const useRenameProject = () => {
  const { renameProject: renameProjectAPI } = useProjectAPI();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: async ({
      projectId,
      newName,
    }: {
      projectId: string;
      newName: string;
    }) => await renameProjectAPI(projectId, newName),
    onMutate: async (variables) => {
      //Cancel any outgoing refetches
      await queryClient.cancelQueries(['projects']);
      await queryClient.cancelQueries(['project', variables.projectId]);

      // Snapshot the previous value
      const previousProject = queryClient.getQueryData<Project>([
        'project',
        variables.projectId,
      ]);

      // Optimistically update to the new value
      if (previousProject) {
        queryClient.setQueryData<Project>(['project', variables.projectId], {
          ...previousProject,
          name: variables.newName,
        });
      }

      // Also update the project list
      const previousProjects = queryClient.getQueryData<Project[]>([
        'projects',
      ]);
      if (previousProjects) {
        queryClient.setQueryData<Project[]>(
          ['projects'],
          previousProjects.map((project) => {
            if (project.id === variables.projectId) {
              return {
                ...project,
                name: variables.newName,
              };
            }
            return project;
          })
        );
      }

      // Return context with the previous value
      return previousProject;
    },
    onError: (_, variables, context) => {
      // Rollback on error
      if (context) {
        queryClient.setQueryData<Project>(
          ['project', variables.projectId],
          context
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['projects']);
    },
  });

  return {
    renameProject: mutate,
    isLoading,
    isError,
  };
};

export default useRenameProject;
