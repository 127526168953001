import { faImages, faPlus, faTrashCan, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import PageTitle from '../Layout/PageTitle';
import { useCreateWhiteboard } from '../../../hooks/whiteboard/useCreateWhiteboard';
import { useGetWhiteboards } from '../../../hooks/whiteboard/useGetWhiteboards';
import { useState } from 'react';
import { WhiteboardCard } from './WhiteboardCard';

type TabId = 'visible' | 'archived';

type Tab = {
  id: TabId;
  title: string;
  icon: IconDefinition;
};

const tabs: Tab[] = [
  {
    id: 'visible',
    title: 'Tableaux',
    icon: faImages,
  },
  {
    id: 'archived',
    title: 'Archivés',
    icon: faTrashCan,
  },
];

const Whiteboards = () => {
  const { createNewWhiteboard } = useCreateWhiteboard();
  const [activeTab, setActiveTab] = useState<TabId>('visible');
  const { whiteboards } = useGetWhiteboards(activeTab);

  return (
    <div className='flex h-full flex-col gap-4'>
      <div className='flex content-center items-center gap-4'>
        {/* TITLE */}
        <PageTitle title='Tableaux' />
        {/* NEW WHITEBOARD */}
        <Button
          leftIcon={faPlus}
          round
          size={'small'}
          variant={'accent'}
          onClick={() => {
            createNewWhiteboard(
              new Date().toLocaleDateString() +
                ' - ' +
                new Date().toLocaleTimeString()
            );
          }}
        />
      </div>

      {/* TABS */}
      <div className='flex gap-2 text-xs'>
        {tabs.map((tab) => (
          <Button
            size='small'
            key={tab.id}
            variant={activeTab === tab.id ? 'primary' : 'tertiary'}
            state={activeTab === tab.id ? 'active' : 'default'}
            round={true}
            onClick={() => setActiveTab(tab.id)}
            leftIcon={tab.icon}>
            {tab.title}
          </Button>
        ))}
      </div>
      {/* WHITEBOARDS */}
      <div className='grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-3 2xl:grid-cols-4'>
        {whiteboards &&
          whiteboards.map((whiteboard) => (
            <WhiteboardCard whiteboardId={whiteboard.id}></WhiteboardCard>
          ))}
      </div>
    </div>
  );
};

export default Whiteboards;
