import Card from '@/components/lib/Containers/Card';
import useGetProject from '@/hooks/projects/useGetProject';
import { useCurrentProjectId } from '../store/currentCreationIdStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFolder, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '@/components/lib/Button';
import { TimeTags } from '../Creations/CreationCard';
import InfoTag from '@/components/lib/InfoTag';
import RenamableProject from './RenamableProject';

import { Whiteboard } from '@/types/api';
import { useNavigate } from 'react-router-dom';
import { useCurrentWhiteboard } from '@/store/currentWhiteboard';
import RenamableWhiteboard from '../Whiteboards/RenamableWhiteboard';

export const ProjectView = () => {
  const { currentProjectId, setCurrentProjectId } = useCurrentProjectId();
  const { project } = useGetProject(currentProjectId as string);

  if (!project) return null;

  return (
    <Card>
      {/* HEADER */}
      <div className='flex justify-between'>
        <div className='flex items-center gap-2 rounded-xl p-1 font-semibold'>
          <FontAwesomeIcon icon={faFolder} />
          <RenamableProject
            projectId={currentProjectId as string}
            key={project.name}
          />
        </div>
        <Button
          round
          leftIcon={faTimes}
          size='xs'
          iconSize='12px'
          onClick={() => {
            setCurrentProjectId(null);
          }}
        />
      </div>

      {/* WHITEBOARDS */}
      <Card.Section>
        <Card.SectionHeader>Tableaux blancs</Card.SectionHeader>
        <div className='flex flex-col gap-2'>
          {project.whiteboards?.map((whiteboard) => {
            return <WhiteboardBlob whiteboard={whiteboard} />;
          })}
        </div>
      </Card.Section>

      {/* DETAILS */}
      <Card.Section>
        <Card.SectionHeader>Détails</Card.SectionHeader>
        <div className='flex gap-4'>
          <Card.SubSection>
            <Card.SubSectionHeader>Créé le</Card.SubSectionHeader>
            <TimeTags createdAt={project.createdAt} />
          </Card.SubSection>
          <Card.SubSection>
            <Card.SubSectionHeader>Tableaux blancs</Card.SubSectionHeader>
            <InfoTag>
              <div className='w-max text-xs'>
                {project.whiteboards?.length || 0}
              </div>
            </InfoTag>
          </Card.SubSection>
        </div>
      </Card.Section>
    </Card>
  );
};

type WhiteboardBlobProps = {
  whiteboard: Whiteboard;
};

const WhiteboardBlob = ({ whiteboard }: WhiteboardBlobProps) => {
  const { setWhiteboardId } = useCurrentWhiteboard();
  const navigate = useNavigate();

  if (whiteboard.status === 'ARCHIVED') return null;
  return (
    <div className='flex items-center justify-between rounded-xl bg-surface-tertiary p-4 text-sm'>
      <div className='flex items-center gap-2'>
        <FontAwesomeIcon icon={faFolder} />
        <RenamableWhiteboard whiteboardId={whiteboard.id} />
      </div>
      <Button
        variant='secondary'
        size='xs'
        iconSize='12px'
        rightIcon={faEye}
        round
        onClick={() => {
          setWhiteboardId(whiteboard.id);
          navigate(`/creator`, {
            state: { whiteboardId: whiteboard.id },
          });
        }}>
        Voir
      </Button>
    </div>
  );
};
