import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeleteImageInAlbum from '../../../../hooks/albums/useDeleteImageInAlbum';
import AlbumImage from './AlbumImage';

type AlbumImageProps = {
  albumId: string;
  imageId: string;
};

const DeletableAlbumImage = ({ albumId, imageId }: AlbumImageProps) => {
  const { deleteImageFromAlbum } = useDeleteImageInAlbum(albumId, imageId);

  return (
    <div className='relative'>
      <AlbumImage albumId={albumId} imageId={imageId} />

      <div
        className='absolute bottom-full left-full flex -translate-x-2/3 translate-y-2/3 cursor-pointer items-center rounded-full bg-d-surface-bg-base p-1 text-white'
        onClick={() => {
          deleteImageFromAlbum();
        }}>
        <FontAwesomeIcon icon={faTrashCan} className='h-[9px] w-[9px]' />
      </div>
    </div>
  );
};

export default DeletableAlbumImage;
