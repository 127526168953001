import { useQuery } from 'react-query';
import { useCreationsAPI } from './useCreationsAPI';
import { Creation } from '../../types/api';

const useGetCreation = (creationId: string) => {
  const { getCreation } = useCreationsAPI();

  const { data, isLoading, isError, error, status } = useQuery<Creation, Error>(
    {
      queryKey: ['creation', creationId],
      queryFn: async () => await getCreation(creationId),
     
    }
  );

  return {
    creation: data,
    status,
    isLoading,
    isError,
    error,
  };
};

export default useGetCreation;
