import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

import { motion } from 'framer-motion';

type StyleDropdownTriggerProps = {
  icon?: IconDefinition;
  selectedValue: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;

  openingDirection: 'top' | 'bottom';
  className?: string;
};

const DropdownTrigger = ({
  icon,
  selectedValue,
  setIsOpen,
  isOpen,

  openingDirection,
  className,
}: StyleDropdownTriggerProps) => {
  return (
    <motion.div
      layout
      className={twMerge(
        'text-smfocus:bg-d-input-bg-focus relative w-full cursor-pointer rounded-xl bg-d-surface-bg-tertiary p-3 text-d-text-2 text-white',
        className,
        isOpen && 'bg-d-surface-bg-accent text-text-negative'
      )}
      onClick={() => setIsOpen((prev) => !prev)}>
      <div className='group grid grid-cols-[1fr_min-content] items-center justify-between font-jakarta'>
        <div className='grid w-full grid-cols-[min-content_1fr] items-center gap-3 overflow-hidden'>
          {icon && <FontAwesomeIcon icon={icon} className='duration-75' />}
          <div className='w-full overflow-hidden text-ellipsis text-nowrap text-xs'>
            {selectedValue}
          </div>
        </div>
        <div className='flex items-center duration-75'>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`text-sm ${openingDirection === 'top' && 'rotate-180'}`}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default DropdownTrigger;
