import { useQueryClient, useMutation } from 'react-query';
import { Whiteboard } from '../../types/api';
import { useWhiteboardAPI } from './useWhiteboardAPI';

const useArchiveWhiteboard = () => {
  const queryClient = useQueryClient();
  const { archiveWhiteboard: archiveWhiteboardAPI } = useWhiteboardAPI();

  const archiveWhiteboardMutation = useMutation({
    mutationFn: async (whiteboardId: string) => {
      return await archiveWhiteboardAPI(whiteboardId);
    },
    onMutate: async (whiteboardId) => {
      await queryClient.cancelQueries(['whiteboards']);
      await queryClient.cancelQueries(['whiteboard', whiteboardId]);

      const previousWhiteboard = queryClient.getQueryData<Whiteboard>([
        'whiteboard',
        whiteboardId,
      ]);

      if (previousWhiteboard) {
        queryClient.setQueryData<Whiteboard>(['whiteboard', whiteboardId], {
          ...previousWhiteboard,
          status: 'ARCHIVED',
        });
      }

      const previousWhiteboards = queryClient.getQueryData<Whiteboard[]>([
        'whiteboards',
        'visible',
      ]);
      if (previousWhiteboards) {
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'visible'],
          previousWhiteboards.filter(
            (whiteboard) => whiteboard.id !== whiteboardId
          )
        );
      }

      const previousArchivedWhiteboards = queryClient.getQueryData<
        Whiteboard[]
      >(['whiteboards', 'archived']);
      if (previousArchivedWhiteboards && previousWhiteboard) {
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'archived'],
          [...previousArchivedWhiteboards, previousWhiteboard]
        );
      }
      await queryClient.invalidateQueries([
        'project',
        previousWhiteboard?.projectId,
      ]);

      return previousWhiteboard;
    },
    onError: (_, whiteboardId, context) => {
      if (context) {
        queryClient.setQueryData(['whiteboard', whiteboardId], context);
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'visible'],
          (old) => {
            if (old) return [...old, context];
            return [];
          }
        );
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'archived'],
          (old) => {
            if (old)
              return old.filter((whiteboard) => whiteboard.id !== context.id);
            return [];
          }
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['whiteboards']);
      queryClient.invalidateQueries(['project']);//TODO: don't invalidate all projects
    },
  });

  return {
    archiveWhiteboard: (whiteboardId: string) =>
      archiveWhiteboardMutation.mutate(whiteboardId),
    isLoading: archiveWhiteboardMutation.isLoading,
    isError: archiveWhiteboardMutation.isError,
    error: archiveWhiteboardMutation.error,
  };
};

export default useArchiveWhiteboard;
