import {
  faArrowPointer,
  faHourglass,
  faMagicWandSparkles,
  faMessage,
  faPalette,
} from '@fortawesome/free-solid-svg-icons';
import Error from './Error';

import { useCreatorContext } from '../ExcalidrawWhiteboard';

import useAppContext from '../../../hooks/useAppContext';
import { BeinkMode } from '../../../types';
import useInpaint from '../../../hooks/generation/useInpaint';
import useImagine from '../../../hooks/generation/useImagine';

import SimpleButton from '../../lib/SimpleButton';

import { usePanelContext } from './context/usePanelContext';
import { useTranslation } from 'react-i18next';

import { usePanelInputContext } from './context/usePanelInputContext';

import { useAlbumContext } from './context/useAlbumContext';

const GenerateButton = () => {
  const { imagine } = useImagine();
  const { inpaint } = useInpaint();
  const { t } = useTranslation(['panel']);
  const { isPanelOpen } = usePanelContext();

  const {
    hasObjectSelected,
    hasImageSelected,
    hasDrawingSelected,
    exportSelectedElementsAsImage,
    exportForInpainting,
  } = useCreatorContext();
  const { currentMode, loading } = usePanelContext();
  const { selectedAlbum } = useAlbumContext();

  const { inpaintInputText, imagineInputText, graphicStyle, albumCursor } =
    usePanelInputContext();
  const { generationsLeft } = useAppContext();

  const isButtonDeactivated = () => {
    if (!hasObjectSelected || loading || generationsLeft <= 0) {
      return true;
    }
    if (currentMode === BeinkMode.Inpaint) {
      if (!inpaintInputText || !hasImageSelected || !hasDrawingSelected) {
        return true;
      }
    }
    if (currentMode === BeinkMode.Imagine) {
      if (!imagineInputText || graphicStyle === null) {
        return true;
      }
    }
    return false;
  };

  const getButtonContent = () => {
    if (loading) {
      return { text: t('panel:GenerateButton.loading'), icon: faHourglass };
    }

    if (generationsLeft <= 0) {
      return {
        text: t('panel:GenerateButton.disabled.noGenerationsLeft'),
        icon: faHourglass,
      };
    }

    if (currentMode === BeinkMode.Inpaint) {
      if (!inpaintInputText) {
        return {
          text: t('panel:GenerateButton.disabled.noInputText'),
          icon: faMessage,
        };
      }
      if (!hasImageSelected) {
        return {
          text: t('panel:GenerateButton.disabled.noImageSelected'),
          icon: faArrowPointer,
        };
      }
      if (!hasDrawingSelected) {
        return {
          text: t('panel:GenerateButton.disabled.noContourSelected'),
          icon: faArrowPointer,
        };
      }
    }

    if (currentMode === BeinkMode.Imagine) {
      if (!imagineInputText) {
        return {
          text: t('panel:GenerateButton.disabled.noInputText'),
          icon: faMessage,
        };
      }
      if (graphicStyle === null) {
        return {
          text: t('panel:GenerateButton.disabled.noStyleSelected'),
          icon: faPalette,
        };
      }
    }

    if (!hasObjectSelected) {
      return {
        text: t('panel:GenerateButton.disabled.noDrawingSelected'),
        icon: faArrowPointer,
      };
    }

    return {
      text: t('panel:GenerateButton.enabled'),
      icon: faMagicWandSparkles,
    };
  };

  const handleGenerate = async () => {
    if (loading) return;

    const dataImg = await exportSelectedElementsAsImage();
    if (dataImg && imagineInputText && graphicStyle) {
      imagine({
        input: imagineInputText,
        graphicStyle: graphicStyle,
        dataImg,
        inspireAlbumId: selectedAlbum ? selectedAlbum : undefined,
        inspireWeight: albumCursor,
      });
    }
  };

  const handleInpaint = async () => {
    if (loading) return;

    const [dataImg, maskImg] = await exportForInpainting();
    if (dataImg && maskImg && inpaintInputText) {
      inpaint({
        prompt: inpaintInputText,
        scribble: dataImg,
        mask: maskImg,
      });
    }
  };

  const { text, icon } = getButtonContent();

  return (
    <div
      className={`z-[1000] flex flex-col gap-4 ${isPanelOpen && 'sticky bottom-0 mt-2 w-full'} `}>
      <Error />
      <SimpleButton
        showLabel={isPanelOpen}
        width={isPanelOpen ? '100%' : '48px'}
        height={isPanelOpen ? '' : '48px'}
        variant='generateButton'
        state={isButtonDeactivated() ? 'disabled' : 'active'}
        disabled={isButtonDeactivated()}
        iconSize='16px'
        size='md'
        rightIcon={icon}
        onClick={() => {
          if (currentMode === BeinkMode.Imagine) {
            handleGenerate();
          } else {
            handleInpaint();
          }
        }}>
        {text}
      </SimpleButton>
    </div>
  );
};

export default GenerateButton;
