
import React from 'react';

type NavbarSectionProps = {
  children: React.ReactNode;
};

export const NavbarSection = ({ children }: NavbarSectionProps) => {
  return <div className='flex flex-col gap-2'>{children}</div>;
};

export default NavbarSection;