
import useGetProject from '../../../hooks/projects/useGetProject';

type ProjectNameProps = {
  projectId: string;
};

export const ProjectName = ({ projectId }: ProjectNameProps) => {
  const { project } = useGetProject(projectId);

  if (!project) return <>Non classé</>;

  return <>{project.name}</>;
};

export default ProjectName;