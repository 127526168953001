import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { DropdownProps } from './types/dropdown.types';
import DropdownTrigger from './DropdownTrigger';
import DropdownContent from './components/DropdownContent';
import useClickOutside from '../../../hooks/useClickOutside';
import useDropdownPosition from '../../../hooks/useDropdownPosition';
import { Option } from './types/dropdown.types';

const Dropdown = ({
  initialValue,
  options,
  Header,
  onChange,
  placeholder,
  isContentContained = true,
  icon,
  maximumVisibleOptions = Infinity,
  showSelectedWithinList = true,
  search = false,
  triggerStyles,
}: DropdownProps) => {
  const [selected, setSelected] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPosition = useDropdownPosition(dropdownRef);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleSelect = (option: Option) => {
    setSelected(option);
    onChange(option);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelected(null);
    onChange(null);
    setIsOpen(false);
  };

  return (
    <motion.div
      className={`${isContentContained && 'relative'} w-full text-text-negative`}
      ref={dropdownRef}>
      <motion.div layout className='flex items-center gap-2'>
        <DropdownTrigger
          openingDirection={dropdownPosition}
          selectedValue={selected ? selected.label : placeholder}
          icon={icon}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className={triggerStyles}
        />
      </motion.div>

      {isOpen && (
        <DropdownContent
          dropdownPosition={dropdownPosition}
          Header={Header}
          search={search}
          options={options}
          selected={selected}
          showSelectedWithinList={showSelectedWithinList}
          onSelect={handleSelect}
          onClose={() => setIsOpen(false)}
          onReset={handleReset}
          maximumVisibleOptions={maximumVisibleOptions}
        />
      )}
    </motion.div>
  );
};

export default Dropdown;
