import { useAxios } from '../../helpers/ProtectedLayout';
import { Album, AlbumShort } from '../../types/api';
import { AxiosResponse, AxiosError } from 'axios';

export type AlbumImage = {
  id: string;
  src: string;
};

export const useAlbumAPI = () => {
  const axios = useAxios();

  //GET REQUESTS
  //ALBUM
  const getAlbum = async (albumId: string): Promise<Album> => {
    try {
      const response: AxiosResponse<Album> = await axios.get<Album>(
        `/album/${albumId}`
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch album');
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  //ALBUMS
  const getAlbums = async (
    status?: 'archived' | 'visible'
  ): Promise<AlbumShort[]> => {
    try {
      const response: AxiosResponse<Album[]> = await axios.get<Album[]>(
        `/album`,
        {
          params: {
            short: true,
            status: status,
            limit: 1000,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch albums');
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Albums fetch failed: ${error.message}`);
      }
      throw error;
    }
  };
  const getAllAlbums = async (): Promise<AlbumShort[]> => {
    try {
      const response: AxiosResponse<Album[]> = await axios.get<Album[]>(
        `/album?short=true&limit=1000`
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch albums');
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Albums fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  //ARCHIVED ALBUMS
  const getArchivedAlbums = async (): Promise<AlbumShort[]> => {
    try {
      const response: AxiosResponse<Album[]> = await axios.get<Album[]>(
        '/album?short=true&status=archived&limit=1000'
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch archived albums');
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Archived albums fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  //ALBUM IMAGE
  const getAlbumImage = async (
    albumId: string,
    imageId: string
  ): Promise<AlbumImage> => {
    try {
      const response = await axios.get<Blob>(
        `/album/${albumId}/image/${imageId}`,
        {
          responseType: 'blob', // Critical: specify blob response type
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch album images');
      }

      return { id: imageId, src: URL.createObjectURL(response.data) };
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album images fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  //POST REQUESTS
  //ALBUM
  const createAlbum = async (name: string): Promise<Album> => {
    try {
      const response = await axios.post<Album>('/album', {
        name,
        description: 'New Album Description',
      });

      if (response.status !== 201) {
        throw new Error('Failed to create album');
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album create failed: ${error.message}`);
      }
      throw error;
    }
  };

  //IMAGE
  const deleteImageInAlbum = async (
    albumId: string,
    imageId: string
  ): Promise<void> => {
    try {
      const response = await axios.delete(`/album/${albumId}/image/${imageId}`);

      if (response.status === 204) {
        return;
      }

      if (response.status !== 200) {
        throw new Error('Failed to delete image');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Image delete failed: ${error.message}`);
      }
      throw error;
    }
  };

  //PUT REQUESTS
  //ALBUM
  const updateAlbum = async (album: Album): Promise<void> => {
    try {
      const response = await axios.put(`/album/${album.id}`, album);

      if (response.status !== 200) {
        throw new Error('Failed to update album');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album update failed: ${error.message}`);
      }
      throw error;
    }
  };

  const renameAlbum = async (
    album: Album,
    albumId: string,
    newName: string
  ): Promise<void> => {
    try {
      const response = await axios.put(`/album/${albumId}`, {
        ...album,
        name: newName,
      });

      if (response.status !== 204) {
        throw new Error('Failed to rename album');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album rename failed: ${error.message}`);
      }
      throw error;
    }
  };

  //ARCHIVE ALBUM
  const archiveAlbum = async (album: Album, albumId: string): Promise<void> => {
    try {
      const response = await axios.put(`/album/${albumId}`, {
        ...album,
        status: 'archived',
      });

      if (response.status !== 204) {
        throw new Error('Failed to archive album');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album archive failed: ${error.message}`);
      }
      throw error;
    }
  };

  //RESTORE ALBUM
  const restoreAlbum = async (album: Album, albumId: string): Promise<void> => {
    try {
      const response = await axios.put(`/album/${albumId}`, {
        ...album,
        status: 'visible',
      });

      if (response.status !== 204) {
        throw new Error('Failed to restore album');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Album restore failed: ${error.message}`);
      }
      throw error;
    }
  };

  //ALBUM IMAGE
  const addImagesToAlbum = async (
    albumId: string,
    images: File[]
  ): Promise<void> => {
    try {
      const imageUploadUrl = new URL(
        `/album/${albumId}/image`,
        import.meta.env.VITE_API_URL
      );

      for (const image of images) {
        const formData = new FormData();
        formData.append('image', image);

        await axios.post(imageUploadUrl.toString(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        /*   if (response.status !== 201) {
          throw new Error('Failed to upload image');
        } */
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Image upload failed: ${error.message}`);
      }
      throw error;
    }
  };

  return {
    updateAlbum,
    renameAlbum,
    getArchivedAlbums,
    getAlbum,
    getAlbums,
    getAlbumImage,
    createAlbum,
    archiveAlbum,
    getAllAlbums,
    restoreAlbum,

    deleteImageInAlbum,
    addImagesToAlbum,
  };
};
