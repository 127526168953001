
import { useQuery } from 'react-query';
import { useProjectAPI } from './useProjectAPI';
import { ProjectShort } from '../../types/api';

const useGetProjects = (status?: 'archived' | 'visible') => {
  const { getProjects } = useProjectAPI();

  const { data, isLoading, isError, error } = useQuery<ProjectShort[], Error>({
    queryKey: ['projects', status],
    queryFn: async () => getProjects(status),
  });

  return {
    projects: data,
    isLoading,
    isError,
    error,
  };
};

export default useGetProjects;