import { ReactNode, useRef, useState, useEffect } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const HorizontalScroll = ({ children, className = '' }: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showGradient, setShowGradient] = useState(true);
  const dragStartRef = useRef({ startX: 0, scrollLeft: 0 });
  const velocityRef = useRef(0);
  const animationFrameRef = useRef<number>();
  const lastTimeRef = useRef<number>(0);
  const lastXRef = useRef<number>(0);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const isFullyScrolled =
        container.scrollLeft + container.clientWidth >=
        container.scrollWidth - 1;
      setShowGradient(!isFullyScrolled);
    }
  };

  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setIsDragging(true);
    const container = scrollContainerRef.current!;
    dragStartRef.current = {
      startX: e.pageX,
      scrollLeft: container.scrollLeft,
    };
    lastTimeRef.current = Date.now();
    lastXRef.current = e.pageX;
    velocityRef.current = 0;
  };

  const stopDragging = () => {
    setIsDragging(false);
    if (Math.abs(velocityRef.current) > 0.1) {
      startInertiaScroll();
    }
  };

  const onDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const container = scrollContainerRef.current!;
    const { startX, scrollLeft } = dragStartRef.current;
    const x = e.pageX;
    const walk = (x - startX) * 1.5;
    container.scrollLeft = scrollLeft - walk;

    // Calculate velocity
    const now = Date.now();
    const dt = now - lastTimeRef.current;
    const dx = e.pageX - lastXRef.current;
    velocityRef.current = dx / dt;

    lastTimeRef.current = now;
    lastXRef.current = e.pageX;
  };

  const startInertiaScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const animate = () => {
      velocityRef.current *= 0.95; // Decay factor
      container.scrollLeft -= velocityRef.current * 16; // 16ms is approx. one frame

      if (Math.abs(velocityRef.current) > 0.1) {
        animationFrameRef.current = requestAnimationFrame(animate);
      }
    };

    animationFrameRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    handleScroll();
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <div className='relative'>
      <div
        ref={scrollContainerRef}
        className={`no-scrollbar scrollbar-hide relative cursor-grab select-none overflow-x-auto ${
          isDragging ? 'cursor-grabbing' : ''
        } ${className}`}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
        onMouseMove={onDrag}
        onScroll={handleScroll}
        onWheel={(e) => {
          e.preventDefault();
          if (e.deltaY !== 0) {
            e.currentTarget.scrollLeft += e.deltaY;
          }
        }}>
        {children}
      </div>
      {showGradient && (
        <div className='absolute right-0 top-0 z-100 h-full w-32 bg-gradient-to-l from-surface-primary to-transparent' />
      )}
    </div>
  );
};

export default HorizontalScroll;
