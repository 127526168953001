import PanelHeader from './PanelHeader';
import { usePanelContext } from './context/usePanelContext';
import AllGenerations from './Results/AllGenerations';
import { Generate } from './Generate/Generate';
import { BeinkPhase } from '../../../types';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import useOverflowDetection from '../../../hooks/useOverflowDetection';

const PanelContent = () => {
  const { phase, isPanelOpen } = usePanelContext();
  const { isOverflowing, contentRef } = useOverflowDetection();

  return (
    <motion.div
      layout
      className='flex h-full flex-col items-center overflow-hidden excalidraw:bg-d-surface-bg-base-blur excalidraw:backdrop-blur-sm'>
      <AnimatePresence>
        {isOverflowing && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='absolute left-0 top-0 z-[500] h-24 w-full'
              style={{
                backgroundImage:
                  'linear-gradient(180deg,  rgba(10, 25, 36, 1) 70%, rgba(0, 0, 0, 0))',
              }}></motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='h-30 absolute bottom-0 left-0 z-[500] w-full bg-gradient-to-t from-d-surface-bg-base'></motion.div>
          </>
        )}
      </AnimatePresence>
      <motion.div
        layoutScroll
        ref={contentRef}
        style={{ overflowY: isOverflowing ? 'scroll' : 'hidden' }}
        className={twMerge(
          `pointer-events-auto relative flex h-full flex-col overflow-y-auto overflow-x-hidden p-4 pt-0 ${isPanelOpen ? 'w-[380px] max-w-[100%]' : 'w-min'}`,
          !isPanelOpen && 'px-2'
        )}>
        <PanelHeader />

        {phase === BeinkPhase.Generate && <Generate />}
        {phase === BeinkPhase.Results && <AllGenerations />}
      </motion.div>
    </motion.div>
  );
};

export default PanelContent;
