import { useMutation, useQueryClient } from 'react-query';

import { useAlbumContext } from '../../components/Creator/Panel/context/useAlbumContext';
import { usePanelContext } from '../../components/Creator/Panel/context/usePanelContext';
import { useAlbumAPI } from './useAlbumAPI';

import { Album } from '../../types/api';

const useCreateAlbum = () => {
  const queryClient = useQueryClient();
  const { setAlbumToModify } = useAlbumContext();
  const { setShowCreateNewAlbum } = usePanelContext();
  const { createAlbum, addImagesToAlbum, getAlbum } = useAlbumAPI();

  const createAlbumMutation = useMutation({
    mutationFn: async ({ name, images }: { name: string; images: File[] }) => {
      const album = await createAlbum(name);

      await addImagesToAlbum(album.id, images);

      const albumWithImages = await getAlbum(album.id);

      return albumWithImages;
    },
    onSuccess: (album) => {
      queryClient.setQueryData(['album', album.id], album);
      queryClient.setQueryData<Album[]>(['albums'], (oldData) => {
        if (!oldData) return [album];
        return [album, ...oldData];
      });

      setShowCreateNewAlbum(false);
      setAlbumToModify(album.id);
    },
    onSettled: () => {
      queryClient.invalidateQueries('albums');
    },
  });

  return {
    createAlbumFromImages: (name: string, images: File[]) =>
      createAlbumMutation.mutate({ name, images }),
    isLoading: createAlbumMutation.isLoading,
    isError: createAlbumMutation.isError,
    error: createAlbumMutation.error,
  };
};

export default useCreateAlbum;
