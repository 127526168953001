import { useMutation, useQueryClient } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { WhiteboardShort } from '../../types/api';

export const useCreateWhiteboard = () => {
  const queryClient = useQueryClient();
  const { createWhiteboard } = useWhiteboardAPI();

  const createWhiteboardMutation = useMutation({
    mutationFn: async ({
      name,
      projectId,
    }: {
      name: string;
      projectId?: string;
    }) => {
      const whiteboard = await createWhiteboard(name, projectId);
      return whiteboard;
    },
    onSuccess: (whiteboard) => {
      queryClient.setQueryData(['whiteboard', whiteboard.id], whiteboard);
      queryClient.setQueryData<WhiteboardShort[]>(
        ['whiteboards'],
        (oldData) => {
          const whiteboardShort = { id: whiteboard.id, name: whiteboard.name };
          if (!oldData) return [whiteboardShort];
          return [whiteboardShort, ...oldData];
        }
      );
    },
    onSettled: (_, __, { projectId }) => {
      if (projectId) queryClient.invalidateQueries(['project', projectId]);
      queryClient.invalidateQueries('whiteboards');
    },
  });

  return {
    createNewWhiteboard: (name?: string, projectId?: string) =>
      createWhiteboardMutation.mutate({
        name:
          name ??
          new Date().toLocaleDateString() +
            ' - ' +
            new Date().toLocaleTimeString(),
        projectId,
      }),
    isLoading: createWhiteboardMutation.isLoading,
    isError: createWhiteboardMutation.isError,
    error: createWhiteboardMutation.error,
  };
};
