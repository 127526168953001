import { faAngleUp, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import { useNavigate } from 'react-router-dom';

const SidebarHeader = () => {
  const navigate = useNavigate();

  return (
    <div className='flex w-max items-center justify-center gap-12'>
      <div className='h-16 brightness-200 saturate-0'>
        <img
          src='logo-white.svg'
          className='h-full w-full object-cover'
          alt='logo'
        />
      </div>
      <Button
        variant='accent'
        round={true}
        leftIcon={faCirclePlus}
        rightIcon={faAngleUp}
        onClick={() => {
          navigate('/creator');
        }}>
        Créer
      </Button>
    </div>
  );
};

export default SidebarHeader;
