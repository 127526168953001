import { useState } from 'react';
import { useStore } from '@nanostores/react';
import { $generations } from '../../../../store/generations';
import { ExpandableCard } from './ExpandableCard';
import { usePanelContext } from '../context/usePanelContext';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import SimpleButton from '../../../lib/SimpleButton';
import { useTranslation } from 'react-i18next';
import { BeinkPhase } from '../../../../types';
import { LayoutGroup, motion } from 'framer-motion';

const AllGenerations = () => {
  const generations = useStore($generations);
  const { inProgressGeneration, setPhase } = usePanelContext();
  const { t } = useTranslation(['panel']);

  // Définir initialement `activeCard` comme la première carte (la plus récente)
  const [activeCard, setActiveCard] = useState<number | null>(
    inProgressGeneration
      ? -1
      : generations.length > 0
        ? 0 // Ouvrir la première carte initialement (carte la plus récente)
        : null
  );

  return (
    <motion.div
      initial={{ y: '20%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '20%', opacity: 0 }}
      transition={{ ease: 'linear', duration: 0.2 }}
      className='flex w-full flex-col rounded-2xl bg-d-surface-bg-tertiary p-2'>
      {/* Conteneur scrollable pour les cartes */}

      <motion.div
        layout
        className='flex w-full flex-grow flex-col gap-2 overflow-hidden'>
        <LayoutGroup>
          {inProgressGeneration && (
            <ExpandableCard
              key={-1}
              id={-1} // Identifiant unique pour la carte en cours
              isLoading={true}
              initialExpand={true}
              generation={inProgressGeneration}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          )}

          {generations.map((generation, index) => (
            <ExpandableCard
              key={index} // Utilise l'index comme clé unique
              id={index} // Utilise l'index comme identifiant
              initialExpand={index === 0} // Ouvre la première carte initialement
              generation={generation}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          ))}
        </LayoutGroup>
      </motion.div>

      {/* Bouton de fermeture fixe en bas */}
      <motion.div
        layout
        className='sticky bottom-0 z-[1000] flex flex-col items-center justify-center p-2'>
        <SimpleButton
          onClick={() => {
            setPhase(BeinkPhase.Generate);
          }}
          round
          leftIcon={faChevronDown}
          variant='primary'
          size='xs'
          iconSize='12'
          format='rounded'>
          {t('Results.close')}
        </SimpleButton>
      </motion.div>
    </motion.div>
  );
};

export default AllGenerations;
