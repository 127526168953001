import {
  faAngleRight,
  faBookBookmark,
} from '@fortawesome/free-solid-svg-icons';

import SidebarLink from '../Sidebar/SidebarLink';

import { RouteType } from '../../../pages/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allRoutes } from '../../../pages/router';
import { useLocation, useParams } from 'react-router-dom';
import useGetAlbum from '../../../hooks/albums/useGetAlbum';

const Breadcrumbs = () => {
  const location = useLocation();
  const params = useParams();

  //Split the location along "/"
  const locationArray =
    location.pathname === '/' ? [''] : location.pathname.split('/');

  //and add back "/" to each item
  const paths = locationArray.map((item) => '/' + item);

  const computedRoutes = paths
    .map((path) => {
      return allRoutes.find((route) => route.path === path);
    })
    .filter((route) => route !== undefined) as BreadcrumbRoute[];

  //check if the page is album detail page
  const albumId = params['albumId'] ?? null;

  
  return (
    <div className='py-4'>
      <div className='flex items-center'>
        {computedRoutes.map((route, index) => {
          if (!route) return null;
          return (
            <Breadcrumb
              key={route.path}
              route={route}
              index={index}
              length={computedRoutes.length}
            />
          );
        })}
        {albumId && <AlbumDetailBreadcrumb albumId={albumId} />}
      </div>
    </div>
  );
};

type BreadcrumbProps = {
  route: BreadcrumbRoute;
  index: number;
  length: number;
};

type BreadcrumbRoute =
  | Omit<RouteType, 'component' | 'hideInSidebar' | 'index'>
  | undefined;

const Breadcrumb = ({ route, index, length }: BreadcrumbProps) => {
  if (!route) return null;
  return (
    <div className='flex items-center justify-center gap-1'>
      <SidebarLink
        to={route.path}
        activeSelectorId={'breadcrumb-selector'}
        icon={route.icon}
        className='gap-2 text-xs'>
        {route.label}
      </SidebarLink>
      {index < length - 1 && length > 0 && (
        <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      )}
    </div>
  );
};

const AlbumDetailBreadcrumb = ({ albumId }: { albumId: string }) => {
  const { album } = useGetAlbum(albumId);

  if (!album) return null;
  const { name } = album;

  return (
    <div className='flex items-center'>
      <FontAwesomeIcon icon={faAngleRight} className='text-slate-200/50' />
      <Breadcrumb
        route={{
          path: `/albums/${albumId}`,
          icon: faBookBookmark,
          label: name,
        }}
        index={0}
        length={1}
      />
    </div>
  );
};

export default Breadcrumbs;
