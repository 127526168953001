import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const currentWhiteboardIdAtom = atomWithStorage<string | null>(
  'currentWhiteboard',
  null,
  undefined,
  { getOnInit: true }
);

export function useCurrentWhiteboard() {
  const [whiteboardId, setWhiteboardId] = useAtom(currentWhiteboardIdAtom);

  return {
    whiteboardId,
    setWhiteboardId,
    hasWhiteboard: whiteboardId !== null,
  };
}
