import { useMutation, useQueryClient } from 'react-query';
import { Album } from '../../types/api';
import { useAlbumAPI } from './useAlbumAPI';

const useAddImageToAlbum = (album: Album) => {
  const { addImagesToAlbum } = useAlbumAPI();

  const queryClient = useQueryClient();

  const addImageMutation = useMutation({
    mutationFn: async (images: File[]) => {
      await addImagesToAlbum(album.id, images);
    },

    onSettled: () => {
      console.log('Image added to album');
      queryClient.invalidateQueries(['album', album.id]);
    },
  });

  const addImageToAlbum = (images: File[]) => {
    return addImageMutation.mutate(images);
  };

  return {
    addImageToAlbum,
    isUploading: addImageMutation.isLoading,
    isError: addImageMutation.isError,
    error: addImageMutation.error,
  };
};

export default useAddImageToAlbum;
