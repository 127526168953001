import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetAlbumImage from '../../../../hooks/albums/useGetAlbumImage';
import ImageLoading from '../Results/ImageLoading';
import { motion } from 'framer-motion';

type AlbumImageProps = {
  albumId: string;
  imageId: string;
};

const AlbumImage = ({ albumId, imageId }: AlbumImageProps) => {
  const { albumImage, isLoading, error } = useGetAlbumImage(albumId, imageId);

  if (error) {
    return (
      <div className='flex h-full w-full items-center justify-center rounded-xl border border-white'>
        <FontAwesomeIcon icon={faExclamationTriangle} className='text-white' />
      </div>
    );
  }

  if (isLoading || !albumImage) {
    return (
      <motion.div
        layout
        className='flex aspect-square items-center justify-center overflow-hidden rounded-xl'>
        <ImageLoading />
      </motion.div>
    );
  }

  return (
    <motion.div
      layout
      className='flex aspect-square items-center justify-center overflow-hidden rounded-xl'>
      <motion.img
        layout
        src={albumImage.src}
        alt=''
        className='h-full w-full object-cover'
      />
    </motion.div>
  );
};

export default AlbumImage;
