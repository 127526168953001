import { useQueryClient, useMutation } from 'react-query';
import { Whiteboard } from '../../types/api';
import { useWhiteboardAPI } from './useWhiteboardAPI';

const useRestoreWhiteboard = () => {
  const queryClient = useQueryClient();
  const { restoreWhiteboard: restoreWhiteboardAPI } = useWhiteboardAPI();

  const restoreWhiteboardMutation = useMutation({
    mutationFn: async (whiteboardId: string) => {
      return await restoreWhiteboardAPI(whiteboardId);
    },
    onMutate: async (whiteboardId) => {
      await queryClient.cancelQueries(['whiteboards']);
      await queryClient.cancelQueries(['whiteboard', whiteboardId]);

      const previousWhiteboard = queryClient.getQueryData<Whiteboard>([
        'whiteboard',
        whiteboardId,
      ]);

      if (previousWhiteboard) {
        queryClient.setQueryData<Whiteboard>(['whiteboard', whiteboardId], {
          ...previousWhiteboard,
          status: 'VISIBLE',
        });
      }

      const previousArchivedWhiteboards = queryClient.getQueryData<
        Whiteboard[]
      >(['whiteboards', 'archived']);
      if (previousArchivedWhiteboards) {
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'archived'],
          previousArchivedWhiteboards.filter(
            (whiteboard) => whiteboard.id !== whiteboardId
          )
        );
      }

      const previousVisibleWhiteboards = queryClient.getQueryData<Whiteboard[]>(
        ['whiteboards', 'visible']
      );
      if (previousVisibleWhiteboards && previousWhiteboard) {
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'visible'],
          [...previousVisibleWhiteboards, previousWhiteboard]
        );
      }

      return previousWhiteboard;
    },
    onError: (_, whiteboardId, context) => {
      if (context) {
        queryClient.setQueryData(['whiteboard', whiteboardId], context);
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'archived'],
          (old) => {
            if (old) return [...old, context];
            return [];
          }
        );
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards', 'visible'],
          (old) => {
            if (old)
              return old.filter((whiteboard) => whiteboard.id !== context.id);
            return [];
          }
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['whiteboards']);
      queryClient.invalidateQueries(['project']); //TODO: don't invalidate all projects
    },
  });

  return {
    restoreWhiteboard: (whiteboardId: string) =>
      restoreWhiteboardMutation.mutate(whiteboardId),
    isLoading: restoreWhiteboardMutation.isLoading,
    isError: restoreWhiteboardMutation.isError,
    error: restoreWhiteboardMutation.error,
  };
};

export default useRestoreWhiteboard;
