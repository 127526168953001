
import { useQuery } from 'react-query';
import { useProjectAPI } from './useProjectAPI';

const useGetProject = (projectId: string) => {
  const { getProject } = useProjectAPI();

  const { data, isLoading, isError, error, status } = useQuery({
    queryKey: ['project', projectId],
    queryFn: async () => await getProject(projectId),
  });

  return {
    project: data,
    status,
    isLoading,
    isError,
    error,
  };
};

export default useGetProject;
