import { atom, useAtom } from 'jotai';

export const currentCreationIdAtom = atom<string | null>(null);

export function useCurrentCreationId() {
  const [creationId, setCreationId] = useAtom(currentCreationIdAtom);

  return {
    creationId,
    setCreationId,
  };
}

export const currentProjectIdAtom = atom<string | null>(null);

export function useCurrentProjectId() {
  const [currentProjectId, setCurrentProjectId] = useAtom(currentProjectIdAtom);

  return {
    currentProjectId,
    setCurrentProjectId,
  };
}
