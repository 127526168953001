import useGetAlbum from '../../../hooks/albums/useGetAlbum';

type AlbumNameProps = {
  albumId: string;
};

const AlbumName = ({ albumId }: AlbumNameProps) => {
  const { album } = useGetAlbum(albumId);

  if (!album) {
    return <div>Album not found</div>;
  }

  return <p>{album.name}</p>;
};

export default AlbumName;
