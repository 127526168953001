import {
  motion,
  useMotionTemplate,
  useTime,
  useTransform,
} from 'framer-motion';
import { Loader2 } from 'lucide-react';

const ImageLoading = () => {
  const time = useTime();
  const phase = useTransform(() => -time.get() / 10);

  const gradient = useMotionTemplate`linear-gradient(${phase}deg, rgb(128, 128, 128) 0%, rgb(242, 242, 242) 100%)`;

  return (
    <motion.div
      style={{ background: gradient }}
      className='flex h-full w-full items-center justify-center overflow-hidden rounded-xl'>
      <Loader2 className='animate-spin text-white' size={30} />
    </motion.div>
  );
};

export default ImageLoading;
