import { useQueryClient, useMutation } from 'react-query';
import { Album } from '../../types/api';
import { useAlbumAPI } from './useAlbumAPI';

const useArchiveAlbum = () => {
  const queryClient = useQueryClient();
  const { archiveAlbum: archiveAlbumAPI } = useAlbumAPI();

  const archiveAlbumMutation = useMutation({
    mutationFn: async ({
      albumId,
      album,
    }: {
      albumId: string;

      album: Album;
    }) => {
      return await archiveAlbumAPI(album, albumId);
    },
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['albums']);
      await queryClient.cancelQueries(['album', variables.albumId]);

      // Snapshot the previous value
      const previousAlbum = queryClient.getQueryData<Album>([
        'album',
        variables.albumId,
      ]);

      // Optimistically update to the new value
      if (previousAlbum) {
        queryClient.setQueryData<Album>(['album', variables.albumId], {
          ...previousAlbum,
          status: 'archived',
        });
      }

      //Also update the visible album list
      const previousAlbums = queryClient.getQueryData<Album[]>([
        'albums',
        'visible',
      ]);
      if (previousAlbums) {
        queryClient.setQueryData<Album[]>(
          ['albums', 'visible'],
          previousAlbums.filter((album) => album.id !== variables.albumId)
        );
      }
      //Also update the archived album list
      const previousArchivedAlbums = queryClient.getQueryData<Album[]>([
        'albums',
        'archived',
      ]);
      if (previousArchivedAlbums && previousAlbum) {
        queryClient.setQueryData<Album[]>(
          ['albums', 'archived'],
          [...previousArchivedAlbums, previousAlbum]
        );
      }

      // Return context with the previous value
      return previousAlbum;
    },
    onError: (_, variables, context) => {
      // Roll back to the previous value if there's an error
      if (context) {
        queryClient.setQueryData<Album>(['album', variables.albumId], context);
        queryClient.setQueryData<Album[]>(['albums', 'visible'], (old) => {
          if (old) {
            return [...old, context];
          }
          return [];
        });
        queryClient.setQueryData<Album[]>(['albums', 'archived'], (old) => {
          if (old) {
            return old.filter((album) => album.id !== context.id);
          }
          return [];
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['albums']);
    },
  });

  const archiveAlbum = (album: Album, albumId: string) => {
    return archiveAlbumMutation.mutate({ album, albumId });
  };

  return {
    archiveAlbum,
    isLoading: archiveAlbumMutation.isLoading,
    isError: archiveAlbumMutation.isError,
    error: archiveAlbumMutation.error,
  };
};

export default useArchiveAlbum;
