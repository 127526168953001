import { useQuery } from 'react-query';
import { AlbumImage, useAlbumAPI } from './useAlbumAPI';

const useGetAlbumImage = (albumId: string, imageId: string) => {
  const { getAlbumImage } = useAlbumAPI();

  const {
    data: albumImage,
    isLoading,
    isError,
    error,
    status,
    refetch,
  } = useQuery<AlbumImage, Error>({
    queryKey: ['albumImage', albumId, imageId],

    queryFn: async () => {
      return await getAlbumImage(albumId, imageId);
    },
  });

  return {
    albumImage,
    isLoading,
    status,
    isError,
    error,
    refetch,
  };
};

export default useGetAlbumImage;
