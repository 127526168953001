import { useQueryClient, useMutation } from 'react-query';
import { Project } from '../../types/api';
import { useProjectAPI } from './useProjectAPI';

const useRestoreProject = () => {
  const queryClient = useQueryClient();
  const { restoreProject: restoreProjectAPI } = useProjectAPI();

  const restoreProjectMutation = useMutation({
    mutationFn: async (projectId: string) => {
      return await restoreProjectAPI(projectId);
    },
    onMutate: async (projectId) => {
      await queryClient.cancelQueries(['projects']);
      await queryClient.cancelQueries(['project', projectId]);

      const previousProject = queryClient.getQueryData<Project>([
        'project',
        projectId,
      ]);

      if (previousProject) {
        queryClient.setQueryData<Project>(['project', projectId], {
          ...previousProject,
          status: 'VISIBLE',
        });
      }

      const previousArchivedProjects = queryClient.getQueryData<Project[]>([
        'projects',
        'archived',
      ]);
      if (previousArchivedProjects) {
        queryClient.setQueryData<Project[]>(
          ['projects', 'archived'],
          previousArchivedProjects.filter((project) => project.id !== projectId)
        );
      }

      const previousVisibleProjects = queryClient.getQueryData<Project[]>([
        'projects',
        'visible',
      ]);
      if (previousVisibleProjects && previousProject) {
        queryClient.setQueryData<Project[]>(
          ['projects', 'visible'],
          [...previousVisibleProjects, previousProject]
        );
      }

      return previousProject;
    },
    onError: (_, projectId, context) => {
      if (context) {
        queryClient.setQueryData(['project', projectId], context);
        queryClient.setQueryData<Project[]>(['projects', 'archived'], (old) => {
          if (old) return [...old, context];
          return [];
        });
        queryClient.setQueryData<Project[]>(['projects', 'visible'], (old) => {
          if (old) return old.filter((project) => project.id !== context.id);
          return [];
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['projects']);
    },
  });

  return {
    restoreProject: (projectId: string) =>
      restoreProjectMutation.mutate(projectId),
    isLoading: restoreProjectMutation.isLoading,
    isError: restoreProjectMutation.isError,
    error: restoreProjectMutation.error,
  };
};

export default useRestoreProject;
