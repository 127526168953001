import { useMutation, useQueryClient } from 'react-query';
import { useAlbumAPI } from './useAlbumAPI';

interface Album {
  id: string;
  images: { id: string }[];
  // ... other album properties
}

const useDeleteImageInAlbum = (albumId: string, imageId: string) => {
  const queryClient = useQueryClient();
  const { deleteImageInAlbum } = useAlbumAPI();

  const deleteImageMutation = useMutation({
    mutationFn: async () => {
      await deleteImageInAlbum(albumId, imageId);
    },
    onMutate: async () => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['album', albumId]);

      // Snapshot the previous value
      const previousAlbum = queryClient.getQueryData<Album>(['album', albumId]);

      // Optimistically update to the new value
      if (previousAlbum) {
        queryClient.setQueryData<Album>(['album', albumId], {
          ...previousAlbum,
          images: previousAlbum.images.filter((img) => img.id !== imageId),
        });
      }

      // Return context with the previous value
      return { previousAlbum, albumId };
    },
    onError: (_err, _variables, context) => {
      // Roll back to the previous value if there's an error
      if (context?.previousAlbum && context.albumId) {
        queryClient.setQueryData(
          ['album', context.albumId],
          context.previousAlbum
        );
      }
    },


    onSettled: () => {
      queryClient.invalidateQueries(['album', albumId]);
    },
  });

  const deleteImageFromAlbum = () => {
    return deleteImageMutation.mutate();
  };

  return {
    deleteImageFromAlbum,
    isLoading: deleteImageMutation.isLoading,
    isError: deleteImageMutation.isError,
    error: deleteImageMutation.error,
  };
};

export default useDeleteImageInAlbum;
