
import { useKeycloak } from '@react-keycloak/web';
import { useQuery } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { Whiteboard } from '../../types/api';

export const useGetWhiteboard = (whiteboardId: string) => {
  const { keycloak } = useKeycloak();
  const { getWhiteboard } = useWhiteboardAPI();

  const { data, isLoading, isError, error, status } = useQuery<Whiteboard, Error>({
    queryKey: ['whiteboard', whiteboardId],
    queryFn: async () => await getWhiteboard(whiteboardId),
    enabled: !!keycloak.token,
  });

  return {
    whiteboard: data,
    status,
    isLoading,
    isError,
    error,
  };
};