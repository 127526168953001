export enum BeinkMode {
  Imagine = 'Imagine',
  Inpaint = 'Inpaint',
}

export enum DesignStyle {
  Industrial = 'Industrial',
  Line = 'Line',
  Davinci = 'Davinci',
  HandSketch = 'HandSketch',
  FashionDrawing = 'FashionDrawing',
  Scientific = 'Scientific',
  Photorealistic = 'Photorealistic',
}

export enum BeinkPhase {
  Generate = 1,
  Results = 2,
}

export type GenerationFromAPI = {
  id: string;
  createdAt: string;
  urls: string[];
  input: string;
  graphicStyle?: string;
  status: GenerationStatus;
};

export type Proposals = string[];

export enum GenerationStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_PROGRESS = 'in-progress',
  PENDING = 'pending',
  TIMEOUT = 'timeout',
}

export enum UserRole {
  StaffDemo = 'beink-staff-demo',
  VIP = 'beink-users-vip',
  Free = 'beink-users-free',
  Subscriber_1 = 'beink-users-subscription-level-1',
  TeamManager = 'beink-users-team-manager',
  Inpaint = 'beink-users-inpaint',

  Inspire = 'beink-users-inspire',
}
