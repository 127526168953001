import SidebarHeader from './SidebarHeader';
import SidebarLink from './SidebarLink';
import User from './User';
import NavbarSection from './NavbarSection';

import { dashboardRoutes } from '../../../pages/router';

const Sidebar = () => {
  return (
    <div className='z-100 flex h-full w-max flex-col justify-between bg-surface-primary text-slate-200'>
      <div className='flex h-full flex-col justify-between p-4'>
        <div className=''>
          <SidebarHeader />

          <div className='flex flex-col gap-4 py-4'>
            <NavbarSection>
              <div className='flex flex-col gap-2'>
                {dashboardRoutes.map((link) => {
                  if (link.hideInSidebar) return null;
                  return (
                    <SidebarLink
                      key={link.path}
                      to={link.path}
                      icon={link.icon}>
                      {link.label}
                    </SidebarLink>
                  );
                })}
              </div>
            </NavbarSection>
          </div>
        </div>
      </div>
      <User />
    </div>
  );
};

export default Sidebar;
