
import { useQueryClient, useMutation } from 'react-query';
import { Project } from '../../types/api';
import { useProjectAPI } from './useProjectAPI';

const useArchiveProject = () => {
  const queryClient = useQueryClient();
  const { archiveProject: archiveProjectAPI } = useProjectAPI();

  const archiveProjectMutation = useMutation({
    mutationFn: async (projectId: string) => {
      return await archiveProjectAPI(projectId);
    },
    onMutate: async (projectId) => {
      await queryClient.cancelQueries(['projects']);
      await queryClient.cancelQueries(['project', projectId]);

      const previousProject = queryClient.getQueryData<Project>([
        'project',
        projectId,
      ]);

      if (previousProject) {
        queryClient.setQueryData<Project>(['project', projectId], {
          ...previousProject,
          status: 'ARCHIVED',
        });
      }

      const previousProjects = queryClient.getQueryData<Project[]>(['projects', 'visible']);
      if (previousProjects) {
        queryClient.setQueryData<Project[]>(
          ['projects', 'visible'],
          previousProjects.filter((project) => project.id !== projectId)
        );
      }

      const previousArchivedProjects = queryClient.getQueryData<Project[]>(['projects', 'archived']);
      if (previousArchivedProjects && previousProject) {
        queryClient.setQueryData<Project[]>(
          ['projects', 'archived'],
          [...previousArchivedProjects, previousProject]
        );
      }

      return previousProject;
    },
    onError: (_, projectId, context) => {
      if (context) {
        queryClient.setQueryData(['project', projectId], context);
        queryClient.setQueryData<Project[]>(['projects', 'visible'], (old) => {
          if (old) return [...old, context];
          return [];
        });
        queryClient.setQueryData<Project[]>(['projects', 'archived'], (old) => {
          if (old) return old.filter((project) => project.id !== context.id);
          return [];
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['projects']);
    },
  });

  return {
    archiveProject: (projectId: string) => archiveProjectMutation.mutate(projectId),
    isLoading: archiveProjectMutation.isLoading,
    isError: archiveProjectMutation.isError,
    error: archiveProjectMutation.error,
  };
};

export default useArchiveProject;