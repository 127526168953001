import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Toaster } from 'react-hot-toast';
import KeycloakClient from './helpers/keycloak';
import Router from './pages/router';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: Infinity,
    },
  },
});

function App() {
  return (
    <ReactKeycloakProvider authClient={KeycloakClient} initOptions={{}}>
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
