
import { faArrowUpRightFromSquare, faFolder, faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Button from '../../lib/Button';
import { TimeTags } from '../Creations/CreationCard';
import { useCurrentWhiteboard } from '../../../store/currentWhiteboard';
import { useGetWhiteboard } from '../../../hooks/whiteboard/useGetWhiteboard';
import useArchiveWhiteboard from '../../../hooks/whiteboard/useArchiveWhiteboard';
import useRestoreWhiteboard from '../../../hooks/whiteboard/useRestoreWhiteboard';
import RenamableWhiteboard from './RenamableWhiteboard';
import { WhiteboardImage } from './WhiteboardImage';
import { ProjectName } from './ProjectName';

type WhiteboardBlobProps = {
  whiteboardId: string;
  width?: string;
};

export const WhiteboardCard = ({ whiteboardId, width }: WhiteboardBlobProps) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);
  const { setWhiteboardId } = useCurrentWhiteboard();
  const { archiveWhiteboard } = useArchiveWhiteboard();
  const { restoreWhiteboard } = useRestoreWhiteboard();

  const navigate = useNavigate();
  if (!whiteboard) return null;

  const { id, projectId, status } = whiteboard;

  return (
    <div
      className={`flex flex-col gap-3 rounded-xl bg-surface-primary p-4`}
      style={{ width: width }}>
      {/* PREVIEW IMAGE */}
      <div className='aspect-[16/11] w-full rounded-xl bg-surface-secondary'>
        <WhiteboardImage whiteboardId={whiteboardId} />
      </div>

      {/* TITLE */}
      <div className='flex items-center justify-between'>
        <div className=''>
          <div className='flex items-center gap-1 text-xs text-slate-200/60'>
            <FontAwesomeIcon icon={faFolder} />
            {projectId ? <ProjectName projectId={projectId} /> : 'Non classé'}
          </div>
          <h1 className='overflow-hidden text-ellipsis text-nowrap'>
            <RenamableWhiteboard whiteboardId={whiteboardId} />
          </h1>
        </div>
        <Button
          className='text-sm'
          variant='secondary'
          round
          size='small'
          iconSize='10px'
          rightIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
          onClick={() => {
            if (status === 'VISIBLE') archiveWhiteboard(whiteboardId);
            else restoreWhiteboard(whiteboardId);
          }}></Button>
      </div>

      <div className='flex justify-between'>
        {/* DATE */}
        <TimeTags createdAt={whiteboard.createdAt} />
        {/* LINK TO CREATION */}

        <Button
          className='justify-self-end text-sm'
          variant='accent'
          round
          size='small'
          iconSize='10px'
          rightIcon={faArrowUpRightFromSquare}
          onClick={() => {
            setWhiteboardId(id);
            navigate('/creator/' + id);
          }}>
          Ouvrir
        </Button>
      </div>
    </div>
  );
};