import Fuse, { IFuseOptions } from 'fuse.js';
import { useState, useMemo } from 'react';

interface UseSearchOptions<T> {
  keys: (keyof { [K in keyof T]: T[K] extends string ? K : never } & string)[];
  threshold?: number;
  shouldSort?: boolean;
  maximumVisibleOptions?: number;
}

export const useSearch = <T>(
  items: T[] | undefined,
  {
    keys,
    threshold = 0.6,
    shouldSort = true,
    maximumVisibleOptions = Infinity,
  }: UseSearchOptions<T>
) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const fuseOptions: IFuseOptions<T> = {
    keys: keys,
    threshold,
    shouldSort,
  };
  const fuse = useMemo(
    () => (items ? new Fuse(items, fuseOptions) : null),
    [items, keys, threshold, shouldSort]
  );

  const filteredItems = useMemo(() => {
    if (!items) {
      return [];
    }
    if (searchValue.length === 0) {
      const sortedItems = shouldSort
        ? items.sort((a, b) => {
            const firstKey = keys[0];

            return a[firstKey] > b[firstKey] ? 1 : -1;
          })
        : items;
      return sortedItems.slice(0, maximumVisibleOptions);
    }

    return fuse
      ?.search(searchValue)
      .map((result) => result.item)
      .slice(0, maximumVisibleOptions);
  }, [searchValue, items, maximumVisibleOptions, shouldSort, keys]);

  return { searchValue, setSearchValue, filteredItems };
};
