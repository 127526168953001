import { faSignOut, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../helpers/string';
import { UserInfo } from '../../../types/user';

const User = () => {
  const keycloak = useKeycloak();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = (await keycloak.keycloak.loadUserInfo()) as UserInfo;

      setUserInfo(userInfo);
    };

    getUserInfo();
  }, []);

  if (!userInfo) return null;

  const userName = capitalizeFirstLetter(userInfo.given_name);

  return (
    <div className='p-4'>
      <div className='flex w-full items-center justify-between rounded-2xl bg-surface-secondary p-3 px-4'>
        <div className='flex items-center justify-center gap-2'>
          <div className='flex flex-col justify-center'>
            <div className='flex items-center gap-0.5 text-xs text-slate-200/60'>
              <FontAwesomeIcon icon={faUserGroup} style={{ height: 10 }} />
              Team Plan
            </div>
            <div className=''>{userName}</div>
          </div>
        </div>
        <div className='flex gap-4'>
          {/*       <FontAwesomeIcon icon={faSliders} /> */}
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={faSignOut}
            onClick={() => {
              keycloak.keycloak.logout();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default User;
