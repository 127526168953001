import { useQuery } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';

const useGetWhiteboardPreviewImage = (whiteboardId: string) => {
  const { getWhiteboardPreviewImage } = useWhiteboardAPI();

  const {
    data: image,
    isLoading,
    isError,
    error,
    status,
    refetch,
  } = useQuery({
    queryKey: ['whiteboardImage', whiteboardId],
    staleTime: 1000 * 60 * 2,
    retry: 1,

    queryFn: async () => await getWhiteboardPreviewImage(whiteboardId),
  });

  return {
    image,
    isLoading,
    status,
    isError,
    error,
    refetch,
  };
};

export default useGetWhiteboardPreviewImage;
