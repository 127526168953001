import { useMutation, useQueryClient } from 'react-query';
import { Album } from '../../types/api';
import { useAlbumAPI } from './useAlbumAPI';

const useUpdateAlbum = () => {
  const queryClient = useQueryClient();
  const { renameAlbum } = useAlbumAPI();

  const renameAlbumMutation = useMutation({
    mutationFn: async ({
      albumId,
      newName,
      album,
    }: {
      albumId: string;
      newName: string;
      album: Album;
    }) => {
      return await renameAlbum(album, albumId, newName);
    },
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(['albums']);
      await queryClient.cancelQueries(['album', variables.albumId]);

      // Snapshot the previous value
      const previousAlbum = queryClient.getQueryData<Album>([
        'album',
        variables.albumId,
      ]);

      // Optimistically update to the new value
      if (previousAlbum) {
        queryClient.setQueryData<Album>(['album', variables.albumId], {
          ...previousAlbum,
          name: variables.newName,
        });
      }

      //Also update the album list
      const previousAlbums = queryClient.getQueryData<Album[]>(['albums']);
      if (previousAlbums) {
        queryClient.setQueryData<Album[]>(
          ['albums'],
          previousAlbums.map((album) => {
            if (album.id === variables.albumId) {
              return {
                ...album,
                name: variables.newName,
              };
            }
            return album;
          })
        );
      }

      // Return context with the previous value
      return previousAlbum;
    },
    onError: (_, variables, context) => {
      // Roll back to the previous value if there's an error
      if (context) {
        queryClient.setQueryData<Album>(['album', variables.albumId], context);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['albums']);
    },
  });

  const updateAlbumName = (album: Album, albumId: string, newName: string) => {
    return renameAlbumMutation.mutate({ album, albumId, newName });
  };

  return {
    updateAlbumName,
    isLoading: renameAlbumMutation.isLoading,
    isError: renameAlbumMutation.isError,
    error: renameAlbumMutation.error,
  };
};

export default useUpdateAlbum;
