import { ReactNode, useState } from 'react';
import Button from '../Button';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface TabsProps<T extends string> {
  tabs: Tab<T>[];
}

export type Tab<TabId extends string> = {
  id: TabId;
  title: string;
  icon: IconDefinition;
  content: ReactNode;
};

const Tabs = <T extends string>({ tabs }: TabsProps<T>) => {
  const [activeTab, setActiveTab] = useState<T>(tabs[0].id);
  const currentTab = tabs.find((tab) => tab.id === activeTab);
  return (
    <div className='flex flex-col gap-10 overflow-hidden'>
      <div className='flex gap-4'>
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            variant={activeTab === tab.id ? 'primary' : 'tertiary'}
            state={activeTab === tab.id ? 'active' : 'default'}
            round={true}
            className='text-xs'
            onClick={() => setActiveTab(tab.id)}
            leftIcon={tab.icon}>
            {tab.title}
          </Button>
        ))}
      </div>
      <div className='h-full overflow-y-auto'>{currentTab?.content}</div>
    </div>
  );
};

export default Tabs;
