import { ReactNode } from 'react';

type InfoTagProps = {
  children: ReactNode;
};

const InfoTag = ({ children }: InfoTagProps) => {
  return (
    <div className='border-info-tag text-info-tag flex h-full w-min items-center gap-2 rounded-lg border p-2 py-1'>
      {children}
    </div>
  );
};

export default InfoTag;
