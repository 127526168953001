import { useState, useEffect, RefObject } from 'react';
import { debounce } from 'lodash';

const useDropdownPosition = (dropdownRef: RefObject<HTMLDivElement>) => {
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');

  useEffect(() => {
    const updatePosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        setDropdownPosition(spaceBelow < 400 ? 'top' : 'bottom');
      }
    };

    // Debounce the update function with 200ms delay
    const debouncedUpdatePosition = debounce(updatePosition, 200);

    // Initial position check
    updatePosition();
    
    window.addEventListener('resize', debouncedUpdatePosition);

    return () => {
      window.removeEventListener('resize', debouncedUpdatePosition);
      // Cancel any pending debounced calls
      debouncedUpdatePosition.cancel();
    };
  }, [dropdownRef]);

  return dropdownPosition;
};

export default useDropdownPosition;