import { useAxios } from '../../helpers/ProtectedLayout';
import { Creation } from '../../types/api';
import { AxiosResponse, AxiosError } from 'axios';

export const useCreationsAPI = () => {
  const axios = useAxios();

  const getCreations = async (
    page: number
  ): Promise<{ creations: Creation[]; nextPage: number | null }> => {
    try {
      const response: AxiosResponse = await axios.get(
        `/generation?page=${page}&limit=20&short=true`
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch creations');
      }

      const hasMore = response.data.length === 20;

      return {
        creations: response.data,
        nextPage: hasMore ? page + 1 : null,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Creations fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  const getCreation = async (creationId: string): Promise<Creation> => {
    try {
      const response: AxiosResponse = await axios.get(
        `/generation/${creationId}`
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch creation');
      }
      console.log(response.data);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Creation fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  const getCreationImage = async (
    creationId: string,
    imageId: string,
    small: boolean = true
  ): Promise<string> => {
    try {
      const response: AxiosResponse = await axios.get<Blob>(
        `/generation/${creationId}/image/${imageId}`,
        {
          params: { size: small ? 'small' : null },
          responseType: 'blob',
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch creation image');
      }

      return URL.createObjectURL(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Creation image fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  return {
    getCreationImage,
    getCreations,
    getCreation,
  };
};
