import { useKeycloak } from "@react-keycloak/web";
import { usePanelContext } from "../../components/Creator/Panel/context/usePanelContext";
import { GenerationFromAPI, GenerationStatus } from "../../types";
import { $generations } from "../../store/generations";
import { $generationsLeft } from "../../store/generations-left";


const useGenerate = () => {
  const { keycloak } = useKeycloak();

  const { setInProgressGeneration, setLoading, setError } = usePanelContext();

  const getInProgressGeneration = async () => {
    const url = new URL('/user/inProgressGeneration', import.meta.env.VITE_API_URL);

    setLoading(true);
    setError(null);

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) throw res;

        const data = await res.json();
        if (!data) {
          setLoading(false);
          return;
        }

        // Fill with fake URLs (ExpandableCard component expects 4 URLs)
        // TODO: maybe rework ExpandableCard component in order to not need this
        data.urls = ['in-progress-0', 'in-progress-1', 'in-progress-2', 'in-progress-3'];

        return data as GenerationFromAPI;
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setError('default');
      });
  };

  const getGeneration = async (id: string) => {
    const url = new URL(`/generation/${id}`, import.meta.env.VITE_API_URL);

    setError(null);

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) throw res;

        const data = await res.json();
        if (!data) return;

        // Generation failed: display an error message
        if ([GenerationStatus.FAILED,
            GenerationStatus.TIMEOUT].includes(data.status)) {
          setError('default');
        }

        // Generation completed: add it to the list of generations
        if (data.status === GenerationStatus.COMPLETED) {
          $generations.set([
            {
              id: data.id,
              createdAt: data.createdAt,
              urls: data.urls,
              input: data.input,
              graphicStyle: data.graphicStyle,
              status: data.status,
            },
            ...$generations.get(),
          ]);

          $generationsLeft.set($generationsLeft.get() - 1);
        }

        // Generation completed or failed: stop polling
        if ([GenerationStatus.FAILED, 
            GenerationStatus.TIMEOUT,
            GenerationStatus.COMPLETED].includes(data.status)) {
          setInProgressGeneration(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError('default');
      });
  };

  return {
    getInProgressGeneration,
    getGeneration,
  }
};

export default useGenerate;
