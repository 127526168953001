import { useEffect, useState } from 'react';
import useRenameProject from '@/hooks/projects/useRenameProject';
import useGetProject from '@/hooks/projects/useGetProject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

type RenamableProjectProps = {
  projectId: string;
  className?: string;
};

const RenamableProject = ({ projectId, className }: RenamableProjectProps) => {
  const { project } = useGetProject(projectId);

  useEffect(() => {
    if (!project) return;
    setNewName(project.name);
  }, [project?.name]);

  const [newName, setNewName] = useState(project?.name);
  const [isEditing, setIsEditing] = useState(false);

  const { renameProject } = useRenameProject();

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const editName = async () => {
    console.log(newName);
    if (!project) return;
    if (newName === '') {
      setNewName(project.name);
      setIsEditing(false);
      return;
    }
    try {
      console.log(project.id);
      await renameProject({
        projectId: project.id,
        newName: newName ?? project.name,
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update project name', error);
    }
  };

  const keyDownName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      //Check if the name is empty
      if (newName === '') {
        setNewName(project?.name);
        setIsEditing(false);
        return;
      }
      editName();
    }
  };

  return (
    <div
      className={twMerge('flex items-center justify-between gap-2', className)}
      onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type='text'
          value={newName}
          onChange={changeName}
          onKeyDown={keyDownName}
          onBlur={editName}
          autoFocus
          className='w-full border-b border-gray-300 bg-transparent focus:outline-none'
        />
      ) : (
        <div>{project?.name}</div>
      )}
      <div
        className='cursor-pointer opacity-40'
        onClick={() => setIsEditing(true)}>
        <FontAwesomeIcon icon={faPencil} height={10} width={10} />
      </div>
    </div>
  );
};

export default RenamableProject;
