import { useQuery } from 'react-query';
import { useCreationsAPI } from './useCreationsAPI';

const useGetCreationImage = (
  generationId: string,
  imageId: string,
  small: boolean = true
) => {
  const { getCreationImage } = useCreationsAPI();

  const {
    data: image,
    isLoading,
    isError,
    error,
    status,
    refetch,
  } = useQuery({
    queryKey: ['albumImage', generationId, imageId, { small }],

    queryFn: async () => await getCreationImage(generationId, imageId, small),
  });

  return {
    image,
    isLoading,
    status,
    isError,
    error,
    refetch,
  };
};

export default useGetCreationImage;
