import { useQueryClient, useMutation } from 'react-query';
import { Album } from '../../types/api';
import { useAlbumAPI } from './useAlbumAPI';

const useRestoreAlbum = () => {
  const queryClient = useQueryClient();
  const { restoreAlbum: restoreAlbumAPI } = useAlbumAPI();

  const restoreAlbumMutation = useMutation({
    mutationFn: async ({ albumId, album }: { albumId: string; album: Album }) => {
      return await restoreAlbumAPI(album, albumId);
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['albums']);
      await queryClient.cancelQueries(['album', variables.albumId]);

      const previousAlbum = queryClient.getQueryData<Album>([
        'album',
        variables.albumId,
      ]);

      if (previousAlbum) {
        queryClient.setQueryData<Album>(['album', variables.albumId], {
          ...previousAlbum,
          status: 'visible',
        });
      }

      // Update the archived album list
      const previousArchivedAlbums = queryClient.getQueryData<Album[]>([
        'albums',
        'archived',
      ]);
      if (previousArchivedAlbums) {
        queryClient.setQueryData<Album[]>(
          ['albums', 'archived'],
          previousArchivedAlbums.filter((album) => album.id !== variables.albumId)
        );
      }

      // Update the visible album list
      const previousVisibleAlbums = queryClient.getQueryData<Album[]>([
        'albums',
        'visible',
      ]);
      if (previousVisibleAlbums && previousAlbum) {
        queryClient.setQueryData<Album[]>(
          ['albums', 'visible'],
          [...previousVisibleAlbums, previousAlbum]
        );
      }

      return previousAlbum;
    },
    onError: (_, variables, context) => {
      if (context) {
        queryClient.setQueryData<Album>(['album', variables.albumId], context);
        queryClient.setQueryData<Album[]>(['albums', 'archived'], (old) => {
          if (old) {
            return [...old, context];
          }
          return [];
        });
        queryClient.setQueryData<Album[]>(['albums', 'visible'], (old) => {
          if (old) {
            return old.filter((album) => album.id !== context.id);
          }
          return [];
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['albums']);
      queryClient.invalidateQueries(['album', 'archived']);
      queryClient.invalidateQueries(['album', 'visible']);
    },
  });

  const restoreAlbum = (album: Album, albumId: string) => {
    return restoreAlbumMutation.mutate({ album, albumId });
  };

  return {
    restoreAlbum,
    isLoading: restoreAlbumMutation.isLoading,
    isError: restoreAlbumMutation.isError,
    error: restoreAlbumMutation.error,
  };
};

export default useRestoreAlbum;
