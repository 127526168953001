import { faImages, faPlus, faTrashCan, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Button from '@/lib/Button';
import PageTitle from '../Layout/PageTitle';
import useCreateProject from '@/hooks/projects/useCreateProject';
import useGetProjects from '@/hooks/projects/useGetProjects';
import { useState } from 'react';
import { ProjectCard } from './ProjectCard';

type TabId = 'visible' | 'archived';

type Tab = {
  id: TabId;
  title: string;
  icon: IconDefinition;
};

const tabs: Tab[] = [
  {
    id: 'visible',
    title: 'Albums',
    icon: faImages,
  },
  {
    id: 'archived',
    title: 'Archivés',
    icon: faTrashCan,
  },
];

const Projects = () => {
  const { createProject } = useCreateProject();
  const [activeTab, setActiveTab] = useState<'visible' | 'archived'>('visible');
  const { projects } = useGetProjects(activeTab);

  if (!projects) return null;
  return (
    <div className='flex flex-col gap-4 overflow-hidden'>
      <div className='flex items-center gap-2'>
        <PageTitle title='Projets' />
        <Button
          variant={'accent'}
          size={'small'}
          round
          rightIcon={faPlus}
          onClick={() => {
            createProject();
          }}>
          Nouveau
        </Button>
      </div>
      <div className='flex gap-3 text-xs'>
        {/* TABS */}
        {tabs.map((tab) => (
          <Button
            size='small'
            key={tab.id}
            variant={activeTab === tab.id ? 'primary' : 'tertiary'}
            state={activeTab === tab.id ? 'active' : 'default'}
            round={true}
            onClick={() => setActiveTab(tab.id)}
            leftIcon={tab.icon}>
            {tab.title}
          </Button>
        ))}
      </div>
      <div className='grid grid-cols-2 gap-4 overflow-y-auto md:grid-cols-3 lg:grid-cols-4'>
        {projects.map((project) => (
          <ProjectCard key={project.id} projectId={project.id} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
