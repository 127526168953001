import { ReactNode } from 'react';

const Card = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex h-full w-[400px] flex-col gap-3 rounded-2xl bg-surface-primary p-4'>
      {children}
    </div>
  );
};

const Section = ({ children }: { children: ReactNode }) => {
  return <div className='flex flex-col gap-2'>{children}</div>;
};

const SectionHeader = ({ children }: { children: ReactNode }) => {
  return <h1 className='text-base font-semibold'>{children}</h1>;
};

const SubSection = ({ children }: { children: ReactNode }) => {
  return <div className='flex flex-col gap-1'>{children}</div>;
};

const SubSectionHeader = ({ children }: { children: ReactNode }) => {
  return <h2 className='text-xs opacity-50'>{children}</h2>;
};

Card.Section = Section;
Card.SectionHeader = SectionHeader;
Card.SubSection = SubSection;
Card.SubSectionHeader = SubSectionHeader;

export default Card;
