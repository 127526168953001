import { LayoutGroup, motion } from 'framer-motion';

import AlbumImageGallery from './AlbumImageGallery';
import RenamableAlbum from './RenamableAlbum';

import { useAlbumContext } from '../context/useAlbumContext';
import SimpleButton from '../../../lib/SimpleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import useGetAlbum from '../../../../hooks/albums/useGetAlbum';

type AlbumProps = {
  albumId: string;
};

const ModifyAlbum = ({ albumId }: AlbumProps) => {
  const { t } = useTranslation(['panel']);
  const { setSelectedAlbum, setAlbumToModify } = useAlbumContext();

  const { album } = useGetAlbum(albumId);

  if (!album) {
    return null;
  }

  const applyStyle = () => {
    setSelectedAlbum(album.id);
    setAlbumToModify(null);
  };

  return (
    <LayoutGroup>
      <motion.div className='absolute top-0 z-[1000] flex w-full flex-col gap-6 rounded-xl bg-[#CBD5E1] p-5 text-text-negative'>
        {/* HEADER */}
        <motion.div layout className='flex items-center justify-between'>
          <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon>
          <div className=''>{t('panel:Inspire.ModifyAlbum.Title')}</div>
          <div
            className='cursor-pointer text-text-negative'
            onClick={() => {
              setAlbumToModify(null);
            }}>
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          </div>
        </motion.div>

        {/* TITLE */}
        <RenamableAlbum albumId={album.id} />

        {/* IMAGES */}
        <AlbumImageGallery key={album.images.length} album={album} />

        {/* APPLY BUTTON */}
        <SimpleButton
          variant='primary'
          className='bg-green-to-violet-gradient text-white'
          onClick={applyStyle}>
          {t('panel:Inspire.ModifyAlbum.ApplyAlbum')}
        </SimpleButton>
      </motion.div>
    </LayoutGroup>
  );
};

export default ModifyAlbum;
